import './App.css';
import { StoreProvider } from './contexts/Store';
import { ThemeProvider } from '@mui/material/styles';
import theme from './utils/theme';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { recaptchaSiteKey } from './utils/constants';
import { ToastContainer } from 'react-toast';
import Stepper from './components/stepper/Component';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer delay={8001} position="bottom-right" />
      <StoreProvider>
        <GoogleReCaptchaProvider
          reCaptchaKey={recaptchaSiteKey}
          useEnterprise={true}
          container={{
            parameters: {
              badge: 'inline',
              theme: 'dark',
            },
          }}
        >
          <Stepper />
        </GoogleReCaptchaProvider>
      </StoreProvider>
    </ThemeProvider>
  );
}

export default App;
