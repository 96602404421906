import { Box, styled } from "@mui/material";

const ReachedMaxMintedStyle = styled(Box)(() => ({
  minHeight: "400px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export default ReachedMaxMintedStyle;
