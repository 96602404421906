import { Grid, Typography } from '@mui/material';
import React from 'react';
import ButtonBlue from '../form/button-blue/Component';
import GetStartedStyle from '../stepper/get-started/Style';
import { collectTrumpCardsSite } from '../../utils/constants';

const SoldOut = () => {
  return (
    <GetStartedStyle>
      <Typography variant="h1">This Edition Is SOLD OUT.</Typography>
      <Grid container justifyContent={'center'}>
        <Grid item xxxs={12} xs={9} className="py-3 mt-3">
          <Grid container>
            <Grid item xxxs={12} className="mt-3">
              <ButtonBlue
                props={{ variant: 'contained', fullWidth: true }}
                onClick={() =>
                  (window.location.href = `${collectTrumpCardsSite}`)
                }
              >
                Return to CollectTrumpCards.com
              </ButtonBlue>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </GetStartedStyle>
  );
};

export default SoldOut;
