import { ReactNode } from "react";
import { PriceModel } from "./totalPrice";

export enum PackageTypeEnum {
  BASIC = 0,
  BRONZE = 1,
  GUNMETAL = 2,
  GOLD = 3,
  DIAMOND = 5,
  GALA_DINNER = 6
}
export enum DisplayTypeEnum {
  BASIC = 0,
  BRONZE = 1,
  GUNMETAL = 2,
  GOLD = 3,
  DIAMOND = 5,
}

export interface PackageTypeModel {
  [key: number]: string;
}

export interface OfferModel {
  packageType: PackageTypeEnum;
  displayType:DisplayTypeEnum;
  packageRate: PackageRateModel | undefined;
  remaining: number | undefined;
  ribbon: number;
  header: string;
  packageTitle: string;
  properties: PropertyModel[] | undefined;
  features: FeatureModel[] | undefined;
  price: PriceModel;
  numberOfTokens: number;
  quantity: QuantityPackageModel | undefined;
  numberOfShippingItems: number;
  creditCardLimitation?: number;
}
export interface QuantityPackageModel {
  max: number;
  value: number;
}
export interface PackageRateModel {
  rate: number;
  label: "best" | "amazing" | "good";
}

export interface PropertyModel {
  quantity: number;
  name: string;
  image: string;
  physicalItem: PhysicalItem | undefined;
}
interface PhysicalItem {
  selectedSizes: { [key: string]: string };
  sizes?: PhysicalItemSize;
}
interface PhysicalItemSize {
  selected: "options" | "custom";
  options: { [key: string]: string } | undefined;
}
export interface FeatureModel extends PropertyModel {
  label: ReactNode | string;
}

export interface PhysicalItemVariant {
  shoesSKU: string[];
  othersSKU: string[];
}
