import { useCallback } from "react";
import iso31662 from "iso-3166-2";
import DropdownModel from "../models/dropdown";
import stateMapper from "../mappers/stateMapper";

const useStateService = () => {
  const allByCountryName = useCallback((country: string): DropdownModel[] => {
    const selectedCountry = iso31662.country(country);
    if (!selectedCountry) return [];

    const stateNames = Object.values(selectedCountry.sub)
      .map((dto) => dto.name)
      .sort((a, b) => a.localeCompare(b));

    return stateNames.map((name, index) => stateMapper.toModel({ name, index }));
  }, []);

  const getStateByCode = (countryCode: string, code: string): string => {
    const state = iso31662.subdivision(countryCode, code);
    if (!state) return "";
    return state.name;
  };

  return { allByCountryName, getStateByCode };
};

export default useStateService;
