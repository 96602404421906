import { ButtonProps } from "@mui/material";
import { FC, ReactNode } from "react";
import ButtonBlueStyle from "./Style";
import theme from "../../../utils/theme";

type Props = {
  children: ReactNode | ReactNode[] | string;
  props?: ButtonProps & { sx?: object; className?: string }; // Allow sx prop for styles
  included?: boolean;
  isSubmitType?: boolean;
  onClick?: () => void;
};

const ButtonBlue: FC<Props> = ({ children, props, included, isSubmitType = false, onClick }) => {
  // Extract sx from props to merge with internal sx
  const { sx: externalSx, className, ...otherProps } = props || {};

  return (
    <ButtonBlueStyle
      className={className}
      type={isSubmitType ? "submit" : undefined}
      onClick={onClick}
      {...otherProps}
      sx={{
        background: included
          ? `linear-gradient(90deg, ${theme.palette.color9.main}, ${theme.palette.color9.light})`
          : "",
        ...externalSx, // Merge external styles
      }}
    >
      {children}
    </ButtonBlueStyle>
  );
};

export default ButtonBlue;
