import { verifyUserDataEndpoint } from "../utils/constants";
import { api } from "../utils/api";
import { useRecaptchaVerify } from "../hooks/Util";
import { useStoreContext } from "../hooks/Contexts";
import { toast } from "react-toast";
import Web3AuthSignMessageDTO from "../dtos/web3AuthSignMessageDTO";
import { useCallback, useMemo, useState } from "react";
import VerifyUserDTO from "../dtos/verifyUserDTO";
import VerifyUserModel from "../models/verifyUser";
import { verifyUserMapper } from "../mappers/veryUserMapper";
import { getAllShoesSKU, hasPhysicalItem } from "../utils/utils";
import useCountryService from "./Country";

const useVerifyUserService = () => {
  const verifyCaptcha = useRecaptchaVerify();
  const [verifyInProgress, setVerifyInProgress] = useState(false);
  const { store } = useStoreContext();
  const countryService = useCountryService();

  const getSignMessageDTO = useMemo(
    (): Web3AuthSignMessageDTO => ({
      walletAddress: store.cart.user.wallet,
      email: store.cart.kycForm.email,
      zipCode: store.cart.kycForm.postalCode,
      numberOfTokens: store.cart.offer!.numberOfTokens,
      packageType: store.cart.offer!.packageType,
    }),
    [store.cart]
  );

  const getShippingAddressByQuantity = useCallback(() => {
    if (store.cart.offer && !hasPhysicalItem(store.cart.offer)) return;
    const shippingForm = {
      shippingFirstName: store.cart.addresses.shipping.firstName,
      shippingLastName: store.cart.addresses.shipping.lastName,
      shippingAddress1: store.cart.addresses.shipping.street,
      shippingAddress2: store.cart.addresses.shipping.apartment,
      shippingCity: store.cart.addresses.shipping.city,
      shippingState: store.cart.addresses.shipping.state,
      shippingZip: store.cart.addresses.shipping.postalCode,
      shippingCountry: countryService.getCodeByName(store.cart.addresses.shipping.country),
      shippingPhone: store.cart.addresses.shipping.phone,
      sneakers: JSON.stringify(getAllShoesSKU(store.cart.offer!).shoesSKU),
    };
    return shippingForm;
  }, [store.cart]);

  const verify = async (): Promise<VerifyUserModel | null> => {
    try {
      setVerifyInProgress(true);
      const signer = await store.web3Auth.web3AuthProvider.getSigner();
      const signedMessage = await signer.signMessage(JSON.stringify(getSignMessageDTO));

      const captchaToken = await verifyCaptcha();
      const { data } = await api.post<VerifyUserDTO>(verifyUserDataEndpoint as string, {
        message: getSignMessageDTO,
        signature: signedMessage,
        token: captchaToken,
        formData: getShippingAddressByQuantity() ?? {},
      });
      setVerifyInProgress(false);
      return verifyUserMapper(data);
    } catch (error) {
      setVerifyInProgress(false);
      toast.error("User Verify is failed");
      console.error(error);
      return null;
    }
  };
  return { verify, verifyInProgress };
};

export default useVerifyUserService;
