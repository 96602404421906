import React, { ChangeEvent, useEffect, useState } from "react";
import PaymentMethodsStyle from "./Style";
import { Alert, Box, Grid, Typography } from "@mui/material";
import theme from "../../../utils/theme";
import { Checkbox } from "@mui/material";
import ButtonBlue from "../../form/button-blue/Component";
import { useStoreContext } from "../../../hooks/Contexts";
import { ContainerMaxWidth, StoreModel } from "../../../contexts/Store";
import useContractService from "../../../services/Contract";
import { collectTrumpCardsSite, maxMintableTokens } from "../../../utils/constants";
import { WarrningIcon } from "../../../utils/svgIcon";
import TransactionLoader from "../../transaction-loader/Component";
import usePayStatus from "../../../services/PayStatus";
import PayConfigModel from "../../../models/payConfig";
import { componentTracking } from "../../../utils/utils";
import { PackageTypeEnum } from "../../../models/offer";

const PaymentMethods = () => {
  const [color, setColor] = useState(theme.palette.color1.main);
  const { store, setStore } = useStoreContext();
  const [areTermsAccepted, setTermsAccepted] = useState<boolean | null>(
    store.cart.termsAccepted ?? null
  );
  const [userAlreadyPurchasedWithCard, setUserAlreadyPurchasedWithCard] = useState<
    undefined | boolean
  >(undefined);
  const { purchasedWithCreditCardByWalletAddress } = useContractService();
  const { config } = usePayStatus();
  const [loading, setLoading] = useState<boolean>(true);
  const [payConfig, setPayConfig] = useState<PayConfigModel>({} as PayConfigModel);
  const [payError, setPayError] = useState<string | null>(null);
  const { getTokenBalanceOfByWalletAddress } = useContractService();

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(event.target.checked);
  };

  useEffect(() => {
    componentTracking("checkout", {
      checkout_view: "Select Payment Method",
    });

    (async () => {
      setLoading(true);
      setPayConfig(await config());
      const result = await purchasedWithCreditCardByWalletAddress();
      if (result !== undefined) setUserAlreadyPurchasedWithCard(result);
      const balance = await getTokenBalanceOfByWalletAddress(
        store.cart.user.wallet,
        store.web3Auth.web3AuthProvider
      );
      if (balance && balance > 0)
        setStore((prevState) => ({
          ...prevState,
          cart: {
            ...prevState.cart,
            offer: null,
            user: {
              ...prevState.cart.user,
              mintedCards: balance,
            },
          },
        }));
      setLoading(false);
    })();
  }, [store.cart.user.wallet]);

  useEffect(() => {
    localStorage.removeItem("signIn");
    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        backStep: {
          ...prevState.flowControl.steps.getStarted,
          title: "Get Started",
        },
      },
      offer: null,
      cart: {
        ...prevState.cart,
        quantity: 1,
      },
      containerMaxWidth: ContainerMaxWidth.DEFAULT,
    }));
  }, []);

  useEffect(() => {
    if (areTermsAccepted === null) {
      setColor(theme.palette.color1.main);
      return;
    }
    setColor(areTermsAccepted ? theme.palette.color6.main : theme.palette.color11.main);
  }, [areTermsAccepted]);

  const getOfferIdFromURL = (): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("package");
  };

  const handleBuy = (paymentMethod: string) => {
    if (paymentMethod === "CreditCard") {
      if (!payConfig.creditCardPaymentIsEnabled) {
        setPayError(
          "Credit card payment is currently unavailable. Please use cryptocurrency or try again later."
        );
        return;
      }
      if (Number(getOfferIdFromURL()) === PackageTypeEnum.DIAMOND) {
        setPayError("The Diamond package can only be purchased with cryptocurrency.");
        return;
      }
    }
    if (paymentMethod === "Crypto") {
      if (!payConfig.cryptoPaymentIsEnabled) {
        setPayError(
          "Cryptocurrency payment is currently unavailable. Please use credit card or try again later."
        );
        return;
      }
      if (store.cart.user.mintedCards === maxMintableTokens) {
        setPayError(
          "Cryptocurrency payment is unavailable. You have reached the maximum token mint."
        );
        return;
      }
    }
    setPayError("");
    if (paymentMethod === "CreditCard" && (!areTermsAccepted || userAlreadyPurchasedWithCard)) {
      setTermsAccepted(false);
      return;
    }
    if (paymentMethod === "Crypto" && !areTermsAccepted) {
      setTermsAccepted(false);
      return;
    }

    componentTracking("checkout", {
      checkout_click: paymentMethod + " Method Selected",
    });

    setStore(
      (prevState) =>
        ({
          ...prevState,
          cart: {
            ...prevState.cart,
            offer: null,
            paymentMethod: paymentMethod,
            termsAccepted: areTermsAccepted,
          },
          flowControl: {
            ...prevState.flowControl,
            activeStep: {
              ...prevState.flowControl.steps.offers,
              title: "Checkout",
            },
          },
        } as StoreModel)
    );
  };

  const getCheckBoxSVGColor = () => {
    if (areTermsAccepted) return theme.palette.primary.main;
    if (areTermsAccepted === null) return theme.palette.secondary.dark;
    return theme.palette.color1.main;
  };

  if (loading) {
    return <TransactionLoader type="Simple Loading" />;
  }

  return (
    <PaymentMethodsStyle>
      <Typography variant="h1">Select Payment Method</Typography>
      <Grid container justifyContent="center">
        <Grid item xxxs={12} sm={9}>
          <Typography variant="textMediumSemibold" component="div" className="agree-to-the-terms">
            Agree to the terms and select one of the following to continue:
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xxxs={12} sm={9}>
          <Box className="terms-and-conditions" sx={{ backgroundColor: `${color}` }}>
            <Checkbox
              checked={areTermsAccepted ?? false}
              onChange={handleCheckboxChange}
              sx={{ svg: { fill: getCheckBoxSVGColor() }, height: "100%" }}
            ></Checkbox>
            <Typography className="terms-conditions-text" variant="textSmallNormal">
              I accept the{" "}
              <a target="_blank" href={`${collectTrumpCardsSite}/terms`}>
                <Typography
                  component="span"
                  sx={{
                    textDecoration: "underline",
                    color: theme.palette.secondary.dark,
                  }}
                  variant="textSmallNormal"
                >
                  NFT Terms & Conditions
                </Typography>
              </a>{" "}
              and{" "}
              <a target="_blank" href={`${collectTrumpCardsSite}/nft-owners-agreement`}>
                <Typography
                  component="span"
                  sx={{
                    textDecoration: "underline",
                    color: theme.palette.secondary.dark,
                  }}
                  variant="textSmallNormal"
                >
                  Owner's Agreement
                </Typography>
              </a>
              . The Website Offerings, including the Digital Trading Cards, Physical Trading Cards,
              and Limited Edition Gold and Platinum Sneakers are intended as collectible items for
              individual enjoyment only, not for investment purposes. The Website Offerings,
              including the Digital Trading Cards, Physical Trading Cards, and Limited Edition Gold
              and Platinum Sneakers are not political and have nothing to do with any political
              campaign. We are a community of collectors. All Trump Digital Trading Cards: AMERICA
              FIRST Edition, are non-transferrable until January 31, 2025.
            </Typography>
          </Box>
          <Box className="payment-method-buttons">
            {userAlreadyPurchasedWithCard ? (
              <Typography
                variant="textRegularSemibold"
                component="p"
                sx={{
                  paddingBottom: theme.spacing(2),
                  textAlign: "center",
                  color: theme.palette.color11.main,
                  lineHeight: "21px",
                }}
              >
                You have already made a purchase with credit card!
              </Typography>
            ) : (
              ""
            )}
            <ButtonBlue
              props={{
                variant: "outlined",
                fullWidth: true,
              }}
              onClick={() => handleBuy("CreditCard")}
            >
              <Box className="button-title-and-icon">
                <Typography variant="h6">Buy With Credit Card</Typography>{" "}
              </Box>
            </ButtonBlue>
            <Typography component="p" variant="textMediumSmall" className="description">
              Only U.S. Credit Cards accepted
            </Typography>
            <Grid container className="credit-or-crypto-container">
              <Grid item xxxs={5} textAlign="right">
                <svg
                  width="101"
                  height="2"
                  viewBox="0 0 101 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line x1="1" y1="1" x2="100" y2="1" stroke="#E6E6E6" strokeLinecap="round" />
                </svg>
              </Grid>
              <Grid item xxxs={2} textAlign="center">
                <Typography variant="textRegularSemibold">or</Typography>
              </Grid>
              <Grid item xxxs={5}>
                <svg
                  width="101"
                  height="2"
                  viewBox="0 0 101 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line x1="1" y1="1" x2="100" y2="1" stroke="#E6E6E6" strokeLinecap="round" />
                </svg>
              </Grid>
            </Grid>
            <ButtonBlue
              props={{
                variant: "outlined",
                fullWidth: true,
              }}
              onClick={() => handleBuy("Crypto")}
            >
              <Box className="button-title-and-icon">
                <Typography variant="h6">Buy With Crypto</Typography>
              </Box>
            </ButtonBlue>
            <Typography component="p" variant="textMediumSmall" className="description">
              Crypto accepted for both U.S. and International buyers
            </Typography>
          </Box>
          {payError && (
            <Alert sx={{ mt: 4 }} severity="error">
              {payError}
            </Alert>
          )}
          {!areTermsAccepted && areTermsAccepted !== null && (
            <Box
              className="terms-and-conditions"
              sx={{
                backgroundColor: `${theme.palette.color3.main}`,
                marginTop: theme.spacing(3),
              }}
            >
              <Typography variant="textSmallMedium" className="disclaimer-text">
                <WarrningIcon />
                You must agree to the terms and agreement above in order to proceed.
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </PaymentMethodsStyle>
  );
};

export default PaymentMethods;
