import { FC, useEffect, useState } from "react";
import OrderSummeryStyle from "./Style";
import { Box, Button, Typography } from "@mui/material";
import { useStoreContext } from "../../hooks/Contexts";
import usePhysicalItemService from "../../services/PhysicalItem";
import ProductDTO from "../../dtos/productDTO";
import { getAllShoesSKU } from "../../utils/utils";

const OrderSummary: FC = () => {
  const { store, setStore } = useStoreContext();
  const { getProducts } = usePhysicalItemService();
  const [products, setProducts] = useState<ProductDTO[]>([]);
  const skuFromCart = getAllShoesSKU(store.cart.offer!);

  useEffect(() => {
    (async () => {
      setProducts(await getProducts());
    })();
  }, [store.cart.offer!]);

  const getFullName = (sku: string) => {
    return products.find((product) => product.sku === sku)?.fullName ?? "";
  };

  const changePackage = () => {
    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        activeStep: {
          component: prevState.flowControl.steps.offers.component,
          title: "Checkout",
        },
      },
    }));
  };

  return (
    <OrderSummeryStyle>
      <Box className="head">
        <Typography variant="h6">Order Summary</Typography>
        <Button
          disabled={store.cart.isTransactionInProgress || store.cart.threeDSInProgress} className="change-button"
          sx={{
            opacity: store.cart.isTransactionInProgress || store.cart.threeDSInProgress ? 0.6 : 1,
          }}
          onClick={changePackage}>
          Change
        </Button>
      </Box>
      <Typography variant="texNormalbold" className="package-name">
        {store.cart.offer?.packageType === 0 ?
          <>{store.cart.offer?.quantity?.value} Digital Trading Cards</> :
          <>{store.cart.offer?.packageTitle}</>
        }
      </Typography>
      {skuFromCart.shoesSKU.length > 0 && (
        <Box className="shoes-group">
          <Box className="items">
            {skuFromCart.shoesSKU.map((sku, index) => (
              <Typography key={index} variant="textMediumSmall">
                {getFullName(sku)}
              </Typography>
            ))}
          </Box>
        </Box>
      )}
      {skuFromCart.othersSKU.length > 0 && (
        <Box className="others-group">
          <Box className="items">
            {skuFromCart.othersSKU.map((sku, index) => (
              <Typography key={index} variant="textMediumSmall">
                {getFullName(sku)}
              </Typography>
            ))}
          </Box>
        </Box>
      )}
    </OrderSummeryStyle>
  );
};

export default OrderSummary;
