import { Button, styled } from '@mui/material';
import theme from '../../../utils/theme';

const ButtonBlueStyle = styled(Button)((props) => ({
  height: '48px',
  minWidth: props.fullWidth ? '100%' : '150px',
  borderRadius: '50px',
  background:
    props.variant === 'contained'
      ? `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`
      : theme.palette.color1.main,
  textTransform: 'none',
  fontFamily: 'SF UI Display Semibold',
  fontSize: '16px',
  lineHeight: '24px',
  boxShadow: 'none',
  borderColor:
    props.variant === 'contained'
      ? theme.palette.primary.main
      : theme.palette.secondary.dark,
  color:
    props.variant === 'contained'
      ? theme.palette.color1.main
      : theme.palette.secondary.dark,
  '&:hover': {
    borderColor:
      props.variant === 'contained'
        ? theme.palette.primary.main
        : theme.palette.secondary.dark,
    color:
      props.variant === 'contained'
        ? theme.palette.color1.main
        : theme.palette.secondary.dark,
    background:
      props.variant === 'contained'
        ? theme.palette.secondary.dark
        : theme.palette.color1.main,
  },
  '&.pay-button': {
    position: 'sticky',
    top: 0,
    bottom: '5px',
    background: props.disabled ? theme.palette.color16.main : '',
    color: props.disabled ? theme.palette.color1.main : '',
    '&:hover': {
      background: props.disabled ? 'inherit' : '',
      borderColor: props.disabled ? 'inherit' : '',
    }
  }
}));

export default ButtonBlueStyle;
