import { FC } from "react";
import FooterStyle from "./Style";
import { Container, Grid, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { collectTrumpCardsSite } from "../../../utils/constants";

const Footer: FC = (): JSX.Element => {
  const privacyLink = `${collectTrumpCardsSite}/privacy`;
  const termsOfUseLink = `${collectTrumpCardsSite}/terms`;

  return (
    <FooterStyle>
      <Container>
        <Grid container justifyContent="space-between" className="desktop-footer">
          <Grid item>
            <Typography variant="textSmallNormal">
              © {DateTime.now().year} NFT INT LLC. All right reserved.
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={5}>
              <Grid item>
                <a target="_blank" href={privacyLink}>
                  <Typography variant="textSmallLink">Privacy Policy</Typography>
                </a>
              </Grid>
              <Grid item>
                <a target="_blank" href={termsOfUseLink}>
                  <Typography variant="textSmallLink">Terms of Use</Typography>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" className="mobile-footer">
          <Grid item>
            <Grid container spacing={5}>
              <Grid item>
                <a href={privacyLink}>
                  <Typography variant="textSmallMedium">Privacy Policy</Typography>
                </a>
              </Grid>
              <Grid item>
                <a href={termsOfUseLink}>
                  <Typography variant="textSmallMedium">Terms of Use</Typography>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </FooterStyle>
  );
};

export default Footer;
