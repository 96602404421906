import { Box, styled } from "@mui/material";

const OrderSummeryStyle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.color23.main,
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  ".head": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ".change-button": {
      color: theme.palette.secondary.dark,
      textTransform: "capitalize",
      backgroundColor: theme.palette.color3.main,
      borderRadius: "50px",
      padding: "0px",
      minWidth: "73px",
    },
  },
  ".package-name": {
    textDecoration: "underline",
    margin: `${theme.spacing(1)} 0`,
  },
  ".items": {
    display: "flex",
    flexDirection: "column",
  },
}));

export default OrderSummeryStyle;
