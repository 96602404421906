import { TextField } from "@mui/material";
import {
  smartyInternationalAutoCompleteApi,
  smartyKey,
  smartyUsAutoCompleteApi,
} from "../../../utils/constants";
import axios from "axios";
import {
  SmartyInternationalCanditateItemDTO,
  SmartyInternationalSuggestionDTO,
  SmartyUsAutoCompleteDTO,
} from "../../../dtos/SmartyRequestDTO";
import useCountryService from "../../../services/Country";
import { toModel as toSmartyModel } from "../../../mappers/smartyMapper";
import { FC, useEffect, useRef, useState } from "react";
import { SmartyModel } from "../../../models/smarty";
import StreetInputStyle from "./Style";
import useStateService from "../../../services/State";
import { getRandomNumber } from "../../../utils/utils";

const INITIAL_SELECTED: SmartyModel = {
  street: "",
  city: "",
  zipcode: "",
  state: "",
  entries: 0,
  isInternational: false,
  inputText: "",
};

type Props = {
  country: string;
  value: string;
  onChange: (property: string, value: string) => void;
};

const StreetInput: FC<Props> = ({ country, onChange, value }) => {
  const { getCodeByName } = useCountryService();
  const { getStateByCode } = useStateService();
  const [streets, setStreets] = useState<SmartyModel[]>([]);
  const countryRef = useRef<string>("");

  const getSuggestionsForUs = async (search: string, suggestion?: any) => {
    const stringToReturn = `${suggestion?.street_line} ${suggestion?.secondary} (${suggestion?.entries}) ${suggestion?.city} ${suggestion?.state} ${suggestion?.zipcode}`;

    try {
      const { data } = await axios.get<SmartyUsAutoCompleteDTO>(
        `${smartyUsAutoCompleteApi}&search=${search}${
          suggestion ? `&selected=${stringToReturn}` : ""
        }&license=us-autocomplete-pro-cloud`
      );
      const model = data.suggestions.map((dto) => toSmartyModel(dto, false, dto.entries));
      setStreets(model);
    } catch (err) {
      console.error(err);
    }
  };

  const getSuggestionsForInternation = async (
    country: string,
    search: string,
    address_id?: string,
    entries?: number
  ) => {
    try {
      if (entries && entries === 1) {
        const { data } = await axios.get<SmartyInternationalCanditateItemDTO>(
          `${smartyInternationalAutoCompleteApi}${
            address_id ? "/" + address_id : ""
          }&country=${country}&search=${search}&license=international-autocomplete-v2-cloud&key=${smartyKey}`
        );
        setStreets([toSmartyModel(data.candidates[0], true, 1)]);
      } else {
        const { data } = await axios.get<SmartyInternationalSuggestionDTO>(
          `${smartyInternationalAutoCompleteApi}${
            address_id ? "/" + address_id : ""
          }?&country=${country}&search=${search}&license=international-autocomplete-v2-cloud&key=${smartyKey}`
        );
        setStreets(data.candidates.map((dto) => toSmartyModel(dto, true, dto.entries)));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const suggestionCallerHandler = (value: string, addressId?: string, entries?: number) => {
    onChange("street", value);
    if (value.length < 5) {
      setStreets([]);
      return;
    }

    const code = getCodeByName(country || "");
    if (!code || code === "US") {
      getSuggestionsForUs(value);
    } else {
      getSuggestionsForInternation(code, value, addressId, entries);
    }
  };

  const selectedOptionHandler = async (value: SmartyModel) => {
    const countryCode = getCodeByName(country || "");
    if (!value.isInternational) {
      if (value.entries > 1) {
        const search = `${value.street}${
          value.secondaryAddress ? " " + value.secondaryAddress : ""
        }`;
        const selectedString = `${search} (${value.entries}) ${value.city} ${value.state} ${value.zipcode}`;

        const searchEncoded = encodeURIComponent(search);
        const selectedEncoded = encodeURIComponent(selectedString);

        const { data } = await axios.get<SmartyUsAutoCompleteDTO>(
          `${smartyUsAutoCompleteApi}&search=${searchEncoded}&selected=${selectedEncoded}&license=us-autocomplete-pro-cloud`
        );
        const model = data.suggestions.map((dto) => toSmartyModel(dto, false, dto.entries));
        setStreets(model);
        return;
      }
      let street = `${value.street} ${value.secondaryAddress || ""}`;
      onChange("city", value.city);
      onChange("postalCode", value.zipcode);
      onChange("state", getStateByCode(getCodeByName(country || "") ?? "", value.state));
      onChange("street", street);
      return;
    }
    if (value.entries > 1 && value.isInternational) {
      const { data } = await axios.get<SmartyInternationalSuggestionDTO>(
        `${smartyInternationalAutoCompleteApi}${
          value.streetId ? "/" + value.streetId : ""
        }?&country=${country}&search=${
          value.street
        }&license=international-autocomplete-v2-cloud&key=${smartyKey}`
      );
      setStreets(data.candidates.map((dto) => toSmartyModel(dto, true, dto.entries)));
      onChange("street", value.street);
      return;
    }
    const { data } = await axios.get<SmartyInternationalCanditateItemDTO>(
      `${smartyInternationalAutoCompleteApi}${value.streetId ? "/" + value.streetId : ""}?${
        value.isInternational ? "&country=" + countryCode : ""
      }&search=${value.street}&license=international-autocomplete-v2-cloud&key=${smartyKey}`
    );
    const model = toSmartyModel(data.candidates[0], value.isInternational, value.entries);
    onChange("city", model.city);
    onChange("postalCode", model.zipcode);
    onChange("state", getStateByCode(getCodeByName(country || "") ?? "", model.state));
    onChange("street", model.street);
  };

  const clearHandler = () => {};

  useEffect(() => {
    if (country !== countryRef.current) {
      countryRef.current = country;
      setStreets([]);
    }
  }, [country]);

  return (
    <StreetInputStyle
      freeSolo
      clearText=""
      value={value ? { ...INITIAL_SELECTED, street: value } : INITIAL_SELECTED}
      getOptionLabel={(option) => {
        const current: SmartyModel = option as SmartyModel;
        let currentString = current.street ?? "" + current.secondaryAddress ?? "";
        return currentString;
      }}
      renderOption={(props, option) => {
        const model = option as SmartyModel;
        return (
          <li {...props} key={`model-street-${getRandomNumber()}`}>
            {model.inputText} {model.entries > 1 && `(${model.entries} address)`}
          </li>
        );
      }}
      options={streets}
      onChange={(e, value, react) => {
        if (react === "clear") return clearHandler();
        const model = value as SmartyModel;
        if (!model) return;
        return selectedOptionHandler(model);
      }}
      onInputChange={(e, value) => {
        suggestionCallerHandler(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Street"
          InputProps={{
            ...params.InputProps,
            type: "Street",
          }}
        />
      )}
    />
  );
};

export default StreetInput;
