import React, { FC, useEffect, useState } from "react";
import { Box, Button, Typography, Collapse, SvgIcon, Grid, useMediaQuery } from "@mui/material";
import { useStoreContext } from "../../hooks/Contexts";
import theme from "../../utils/theme";
import stripe from "../../assets/images/stripe.png";
import CheckoutCardStyle from "./Style";
import { GreenCheckIcon, CircleIcon, InfoIcon } from "../../utils/svgIcon";
import { FlameIcon } from "../../utils/svgIcon";
import OffersList from "./offer-list/Component";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { OfferModel, PackageTypeEnum } from "../../models/offer";
import { componentTracking, isOnlyBuyWithCrypto } from "../../utils/utils";
import { ActiveStepModel } from "../../contexts/Store";
import { PACKAGE_TYPES } from "../stepper/offers/OffersData";
import { maxMintableTokens } from "../../utils/constants";

enum Selection {
  ONLY_AVAILABLE_CRYPTO = "Only Available to Crypto buyers",
  ADD_TO_CART = "Add to cart",
}

interface Props {
  offer: OfferModel;
  openedShowMore?: boolean;
}

const CheckoutCard: FC<Props> = ({ offer, openedShowMore = false }) => {
  const { store, setStore } = useStoreContext();
  const [isShowMoreOpened, setisShowMoreOpened] = useState(openedShowMore);
  const handleShowMoreClick = () => {
    setisShowMoreOpened(!isShowMoreOpened);
  };
  const mdMediaQuery = useMediaQuery(theme.breakpoints.up("md"));

  const exceededMaxMint = maxMintableTokens < offer.numberOfTokens + store.cart.user.mintedCards;

  useEffect(() => {
    setisShowMoreOpened(openedShowMore);
  }, [openedShowMore]);

  const OfferType = () => {
    return (
      <Box className="offer-type-box">
        <Box sx={{ display: "flex", alignItems: "center", gap: "1px" }}>
          {[...Array(offer.packageRate!.rate)].map((_, index) => (
            <FlameIcon key={index} />
          ))}
          <Typography variant="textMediumBold" className="offer-type-label">
            {offer.packageRate!.label} offer
          </Typography>
        </Box>
      </Box>
    );
  };

  const ShowMoreButton = () => {
    return (
      <Box className="show-more-box" onClick={handleShowMoreClick}>
        <SvgIcon component={InfoIcon} />
        <Typography variant="texNormalSemibold">
          {isShowMoreOpened ? "Close Full Details" : "Show More"}
        </Typography>
        {isShowMoreOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
      </Box>
    );
  };

  const makeStripe = () => {
    if (offer.ribbon === -1) return null;
    return (
      <Box sx={{ position: "absolute", top: "47px", left: "-24px" }}>
        <Box className="red-flag">
          <Typography variant="textSmallBold" className="limited-text">
            Limited
            {offer.ribbon > 0 && (
              <>
                <br /> first {offer.ribbon}
              </>
            )}
          </Typography>
        </Box>
      </Box>
    );
  };

  const getSelectionText = (): Selection => {
    if (
      (isOnlyBuyWithCrypto(offer) || offer.packageType === PackageTypeEnum.DIAMOND) &&
      store.cart.paymentMethod !== "Crypto"
    )
      return Selection.ONLY_AVAILABLE_CRYPTO;
    return Selection.ADD_TO_CART;
  };

  const onSelect = () => {
    componentTracking("checkout", {
      checkout_click: `${PACKAGE_TYPES[offer.packageType]} Package Selected`,
    });

    if (getSelectionText() === Selection.ONLY_AVAILABLE_CRYPTO) {
      setStore((prevState) => ({
        ...prevState,
        flowControl: {
          ...prevState.flowControl,
          activeStep: prevState.flowControl.backStep as ActiveStepModel,
        },
      }));
    } else {
      setStore((prevState) => ({
        ...prevState,
        cart: {
          ...prevState.cart,
          offer,
        },
      }));
    }
  };

  const checkIfOfferSelected = () => {
    if (store.cart.offer?.packageType === offer.packageType) {
      return true;
    } else {
      return false;
    }
  };

  const availabilityBox = () => (
    <Box>
      {exceededMaxMint ? (
        <Typography variant="h6" component="span" sx={{ color: theme.palette.color2.main }}>
          Not available
        </Typography>
      ) : (
        <>
          {offer.remaining !== undefined ? (
            <Typography variant="h6" component="span" sx={{ color: theme.palette.color2.main }}>
              {offer.remaining <= 0 && <>Closed out</>}
            </Typography>
          ) : (
            <Typography variant="h6" component="span" sx={{ color: theme.palette.color2.main }}>
              Available
            </Typography>
          )}
        </>
      )}
    </Box>
  );

  const isHigherBox =
    (offer.packageType === PackageTypeEnum.GOLD ||
      offer.packageType === PackageTypeEnum.GALA_DINNER ||
      offer.packageType === PackageTypeEnum.DIAMOND) &&
    mdMediaQuery;

  return (
    <Box
      className="checkout-card"
      sx={{ filter: `grayscale(${exceededMaxMint || offer.remaining === 0 ? 1 : 0})` }}
    >
      <Box
        className="availability-container"
        sx={{
          justifyContent: offer.remaining !== undefined ? "space-between" : "center",
          minHeight: "33px",
        }}
      >
        {offer.packageRate && <OfferType />}
        {availabilityBox()}
      </Box>
      <CheckoutCardStyle>
        <Box
          className={`inside-box ${isHigherBox ? "higher" : ""} ${
            store.cart.paymentMethod === "CreditCard" ? "credit-payment" : ""
          }`}
        >
          <Box
            className={`title-stripe ${isShowMoreOpened ? "sticky-offer-header" : ""}`}
            sx={{
              backgroundColor: "#000",
            }}
          >
            {checkIfOfferSelected() ? (
              <Box className="selected-checkbox">
                <GreenCheckIcon />
              </Box>
            ) : (
              <Box className="selected-checkbox">
                <CircleIcon />
              </Box>
            )}
            {stripe && makeStripe()}
            <Box className="titles">
              <Typography
                variant="textMediumSemibold"
                component="p"
                className="offer-header"
                dangerouslySetInnerHTML={{ __html: offer.header }}
              />
              <Typography
                variant="textMediumSemibold"
                component="p"
                className="offer-package-title"
              >
                {offer.packageTitle}
              </Typography>
            </Box>
          </Box>
          <OffersList properties={offer.properties} />
          <ShowMoreButton />
          <Collapse in={isShowMoreOpened}>
            <Grid container className="additional-details" spacing={2}>
              {offer.features!.map((bonus, index) => (
                <Grid item key={index}>
                  <Box className="additional-details-item">
                    <Box className="bonus-type-box">
                      <Typography
                        variant="textSmallBold"
                        className="bonus-type-label"
                        component="p"
                      >
                        BONUS
                        <br />#{bonus.label}
                      </Typography>
                    </Box>
                    <Typography
                      className="bonus-text"
                      dangerouslySetInnerHTML={{ __html: bonus.name }}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <img src={bonus.image} />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Collapse>
          {!exceededMaxMint && (offer.remaining === undefined || offer.remaining > 0) && (
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                flex: 1,
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
              }}
            >
              <Button onClick={onSelect} className="add-to-cart">
                <Typography
                  variant="textUppercase"
                  sx={{
                    color: theme.palette.color20.main,
                    textDecoration: "underline",
                    lineHeight: "29px",
                  }}
                >
                  {getSelectionText()}
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
      </CheckoutCardStyle>
    </Box>
  );
};

export default CheckoutCard;
