import React, {
  createContext,
  ReactNode,
  FC,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import useWeb3Auth from "../hooks/Web3Auth";
import { Web3Auth } from "@web3auth/modal";
import GetStarted from "../components/stepper/get-started/Component";
import BillingAddressForm from "../components/stepper/billing-address-form/Component";
import ShippingAddressForm from "../components/stepper/shipping-address-form/Component";
import SignIn from "../components/stepper/sign-in/Component";
import KycForm from "../components/stepper/kyc-form/Component";
import KYC from "../components/kyc/Component";
import PaymentMethods from "../components/stepper/payment-methods/Component";
import CreditCardPayment from "../components/stepper/credit-card-payment/Component";
import CryptoPayment from "../components/stepper/cripto-payment/Component";
import theme from "../utils/theme";
import SoldOut from "../components/sold-out/Component";
import Offers from "../components/stepper/offers/Component";
import { OfferModel } from "../models/offer";
import PhysicalItemSizes from "../components/stepper/physical-item-sizes/Component";
import DiamondMinter from "../components/stepper/diamond-minter/Component";
import ReachedMaxMinted from "../components/stepper/reached-max-minted/Component";

export interface StepModel {
  component: React.ReactElement;
}

export enum ContainerMaxWidth {
  DEFAULT = 716,
  MIDDLE = 1092,
  FULL = theme.breakpoints.values.lg,
}

export interface ActiveStepModel extends StepModel {
  title: "Get Started" | "Sign In" | "Checkout";
  componentName?: string;
}

interface StepsModel {
  getStarted: StepModel;
  signIn: StepModel;
  paymentMethods: StepModel;
  offers: StepModel;
  checkout: StepModel;
  billingAddressForm: StepModel;
  shippingAddressForm: StepModel;
  kycForm: StepModel;
  kyc: StepModel;
  creditCardPayment: StepModel;
  cryptoPayment: StepModel;
  diamondMinter: StepModel;
  soldOut: StepModel;
  physicalItemSizes: StepModel;
  reachedMaxMinted: StepModel;
}

interface FlowControlModel {
  activeStep: ActiveStepModel;
  steps: StepsModel;
  backStep: ActiveStepModel | undefined;
  highlightedSteps: string[];
}

export interface StoreContextModel {
  store: StoreModel;
  setStore: Dispatch<SetStateAction<StoreModel>>;
  setTransactionInProgress: (value: boolean) => void;
  setThreeDSInProgress: (value: boolean) => void;
}

export interface Address {
  firstName: string;
  lastName: string;
  country: string;
  street: string;
  city: string;
  state: string;
  postalCode: string;
}

export interface BillingAddress extends Address {
  email: string;
  confirmMailingAddress: string;
}

export interface ShippingAddress extends Address {
  apartment: string;
  phone: string;
}

interface User {
  wallet: string;
  email: string;
  mintedCards: number;
}

export interface KYCForm {
  email: string;
  emailConfirmation: string;
  postalCode: string;
}

export interface CartModel {
  offer: OfferModel | null;
  kycForm: KYCForm;
  addresses: {
    billing: BillingAddress;
    shipping: ShippingAddress;
  };
  price: {
    subTotal: number;
    tax?: number;
    currency: "$" | "WETH";
  };
  paymentMethod: "CreditCard" | "Crypto";
  termsAccepted: boolean | null;
  user: User;
  isTransactionInProgress: boolean;
  threeDSInProgress: boolean;
  polygonScanHash: string | null;
  creditCardTransactionErrorMessage: string | null;
}

export interface StoreModel {
  web3Auth: {
    web3AuthInstance: Web3Auth | null;
    web3AuthProvider: any;
    isInitialized: boolean;
  };
  flowControl: FlowControlModel;
  containerMaxWidth: number;
  cart: CartModel;
}

export const StoreContext = createContext<StoreContextModel>({} as StoreContextModel);

type Props = {
  children: ReactNode;
};

const initializeActiveStep = (): ActiveStepModel => {
  if (localStorage.getItem("signIn")) {
    return {
      title: "Sign In",
      component: <SignIn />,
    };
  }

  return {
    title: "Get Started",
    component: <GetStarted />,
  };
};

export const initialState: StoreModel = {
  web3Auth: {
    web3AuthInstance: null,
    web3AuthProvider: null,
    isInitialized: false,
  },
  flowControl: {
    activeStep: initializeActiveStep(),
    highlightedSteps: ["Get Started", "Sign In", "Checkout"],
    steps: {
      getStarted: {
        component: <GetStarted />,
      },
      signIn: {
        component: <SignIn />,
      },
      paymentMethods: {
        component: <PaymentMethods />,
      },
      offers: {
        component: <Offers />,
      },
      checkout: {
        component: <GetStarted />,
      },
      billingAddressForm: {
        component: <BillingAddressForm />,
      },
      shippingAddressForm: {
        component: <ShippingAddressForm />,
      },
      physicalItemSizes: {
        component: <PhysicalItemSizes />,
      },
      kycForm: {
        component: <KycForm />,
      },
      kyc: {
        component: <KYC />,
      },
      creditCardPayment: {
        component: <CreditCardPayment />,
      },
      cryptoPayment: {
        component: <CryptoPayment />,
      },
      diamondMinter: {
        component: <DiamondMinter />,
      },
      soldOut: {
        component: <SoldOut />,
      },
      reachedMaxMinted: {
        component: <ReachedMaxMinted />,
      },
    },
    backStep: undefined,
  },
  containerMaxWidth: ContainerMaxWidth.DEFAULT,
  cart: {
    kycForm: {
      email: "",
      emailConfirmation: "",
      postalCode: "",
    },
    offer: null,
    addresses: {
      billing: {},
      shipping: {},
    },
    user: {
      wallet: "",
      email: "",
      mintedCards: 0,
    },
    price: {
      subTotal: 99.0,
      tax: 7.99,
      currency: "$",
    },
    isTransactionInProgress: false,
    threeDSInProgress: false,
    termsAccepted: null,
    polygonScanHash: null,
    creditCardTransactionErrorMessage: null,
  } as CartModel,
};

export const StoreProvider: FC<Props> = ({ children }) => {
  const [store, setStore] = useState<StoreModel>(initialState);
  const { initialize } = useWeb3Auth();

  useEffect(() => {
    if (store.flowControl.activeStep.title === "Sign In") {
      const initWeb3Auth = async () => {
        try {
          const web3AuthInstance = await initialize();
          setStore((prevStore) => ({
            ...prevStore,
            web3Auth: {
              ...prevStore.web3Auth,
              web3AuthInstance: web3AuthInstance,
              isInitialized: true,
            },
          }));
        } catch (error) {
          console.error("Error initializing Web3Auth:", error);
        }
      };
      initWeb3Auth();
    }
  }, [initialize, store.flowControl.activeStep]);

  const setTransactionInProgress = (value: boolean) => {
    setStore((prevState) => ({
      ...prevState,
      cart: {
        ...prevState.cart,
        isTransactionInProgress: value,
      },
    }));
  };

  const setThreeDSInProgress = (value: boolean) => {
    setStore((prevState) => ({
      ...prevState,
      cart: {
        ...prevState.cart,
        threeDSInProgress: value,
      },
    }));
  };

  return (
    <StoreContext.Provider
      value={{ store, setStore, setTransactionInProgress, setThreeDSInProgress }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
