import { Box, Button, Container, Typography } from "@mui/material";
import ButtonBlue from "../form/button-blue/Component";
import QuantitySelectorStyle from "./Style";
import { BinIcon, CartIcon } from "../../utils/svgIcon";
import { useStoreContext } from "../../hooks/Contexts";
import theme from "../../utils/theme";
import { maxMintableTokens } from "../../utils/constants";

const ProductSelector = () => {
  const { store, setStore } = useStoreContext();

  const exceededMaxMint =
    maxMintableTokens < (store.cart.offer?.numberOfTokens ?? 0) + store.cart.user.mintedCards;

  const removeButton = () => {
    return (
      <Button className="cart-remove-btn" onClick={onRemove}>
        <BinIcon />
        Remove
      </Button>
    );
  };

  const onSelect = () => {
    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        activeStep: {
          ...prevState.flowControl.activeStep,
          component:
            prevState.cart.paymentMethod === "CreditCard"
              ? prevState.flowControl.steps.billingAddressForm.component
              : prevState.flowControl.steps.kycForm.component,
          title: "Checkout",
          componentName: "",
        },
      },
    }));
  };

  const onRemove = () => {
    window.history.pushState("", "", "/");
    setStore((prevState) => ({
      ...prevState,
      cart: {
        ...prevState.cart,
        offer: null,
      },
    }));
  };

  return (
    <QuantitySelectorStyle sx={{ bottom: 0 }}>
      <Container sx={{ maxWidth: `${store.containerMaxWidth}px !important` }}>
        <Box className="product-selector-container">
          <Box className="product-selector-info-box">
            <CartIcon />
            <Typography variant="textRegularNormal" className="your-cart">
              (Your cart)
            </Typography>
            <Typography variant="textSmallBold" sx={{ textDecoration: "underline" }}>
              {store.cart.offer?.packageType === 0 ? (
                <>{store.cart.offer?.quantity?.value} Digital Trading Cards</>
              ) : (
                <>{store.cart.offer?.packageTitle}</>
              )}
            </Typography>
            {removeButton()}
          </Box>
          <ButtonBlue
            onClick={onSelect}
            props={{
              variant: "contained",
              sx: {
                padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
              },
              disabled: exceededMaxMint || store.cart.offer?.remaining === 0,
            }}
            included
          >
            Buy Now with {store.cart.paymentMethod === "CreditCard" ? "Credit Card" : "Crypto"}
          </ButtonBlue>
        </Box>
      </Container>
    </QuantitySelectorStyle>
  );
};

export default ProductSelector;
