import { useEffect, useState } from "react";
import useVerifyUserService from "../../../services/VerifyUser";
import useContractService from "../../../services/Contract";
import { MINTER_ABI, WETH_CONTRACT_ABI } from "../../../utils/abi";
import { minterAddress, wethAddress } from "../../../utils/constants";
import { useStoreContext } from "../../../hooks/Contexts";
import { Box, Divider, Grid, Typography } from "@mui/material";
import theme from "../../../utils/theme";
import ButtonBlue from "../../form/button-blue/Component";
import TransactionLoader from "../../transaction-loader/Component";
import CryptoPaymentStyle from "./Style";
import { ActiveStepModel, ContainerMaxWidth } from "../../../contexts/Store";
import VerifyUserModel from "../../../models/verifyUser";
import { componentTracking, hasPhysicalItem, hasPhysicalItemSize } from "../../../utils/utils";
import OrderSummary from "../../order-summary/Component";
import { PackageTypeEnum } from "../../../models/offer";

const CryptoPayment = () => {
  const [verifyUser, setVerifyUser] = useState<VerifyUserModel | null>(null);
  const verifyUserData = useVerifyUserService();
  const contractService = useContractService();
  const { store, setStore, setTransactionInProgress } = useStoreContext();

  const cryptoBackStepByQuantity = (): ActiveStepModel => {
    if (store.cart.offer && hasPhysicalItem(store.cart.offer)) {
      if (hasPhysicalItemSize(store.cart.offer)) {
        return {
          component: store.flowControl.steps.physicalItemSizes.component,
          title: "Checkout",
        };
      }
      return {
        component: store.flowControl.steps.shippingAddressForm.component,
        title: "Checkout",
      };
    }

    return {
      component: store.flowControl.steps.kycForm.component,
      title: "Checkout",
    };
  };

  useEffect(() => {
    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        backStep: cryptoBackStepByQuantity(),
      },
      containerMaxWidth: ContainerMaxWidth.DEFAULT,
    }));
  }, []);

  useEffect(() => {
    (async () => {
      setVerifyUser(await verifyUserData.verify());
    })();
  }, []);

  const tryToVerifyUser = async () => {
    setVerifyUser(await verifyUserData.verify());
  };

  const mintTheCards = async () => {
    componentTracking("checkout", {
      checkout_click: "Pay with " + store.cart.paymentMethod,
    });
    const minterContract = contractService.create(
      minterAddress as string,
      MINTER_ABI,
      await store.web3Auth.web3AuthProvider.getSigner()
    );
    const wethContract = contractService.create(
      wethAddress as string,
      WETH_CONTRACT_ABI,
      await store.web3Auth.web3AuthProvider.getSigner()
    );
    if (!verifyUser) return;
    await contractService.mint(
      minterContract,
      minterAddress,
      wethContract,
      verifyUser.dto.userNonce,
      verifyUser.dto.signature,
      verifyUser.dto.saleDomain,
      verifyUser.dto.contractPrice,
      verifyUser.prices.contractPrice,
      verifyUser.dto.totalPrice,
      store.cart.offer?.packageType === PackageTypeEnum.DIAMOND
    );
  };

  const priceRow = (label: string, price: string | undefined) => {
    return (
      <Grid container className="prices">
        <Grid item>
          <Typography variant="textMediumNormal">{label}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="textMediumNormal">wETH {price}</Typography>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    setTransactionInProgress(contractService.loading);
  }, [contractService.loading]);

  const getTheTax = () => {
    if (Number(verifyUser?.prices.taxAmount!) === 0) {
      return "";
    } else {
      return priceRow("Sales Tax", verifyUser?.prices.taxAmount!.toFixed(4));
    }
  };

  return (
    <>
      <CryptoPaymentStyle>
        <Typography variant="h1">Confirm Your Purchase</Typography>
        <Typography
          variant="textMediumNormal"
          component="p"
          textAlign="center"
          sx={{ padding: `${theme.spacing(3)} 0` }}
        >
          All transactions are secure and encrypted.
        </Typography>
        {contractService.loading || verifyUserData.verifyInProgress ? (
          <TransactionLoader type={contractService.loading ? "Transaction" : "Simple Loading"} />
        ) : (
          <Grid container>
            <Grid item xxxs={12}>
              <OrderSummary />
            </Grid>
            <Grid item xxxs={12} sx={{ marginTop: "15px" }}>
              <Typography variant="h6">
                Total Card Quantity: {store.cart.offer?.numberOfTokens}
              </Typography>
            </Grid>
            {verifyUser ? (
              <Grid item xxxs={12}>
                <Box sx={{ paddingBottom: theme.spacing(6) }}>
                  <Divider />
                  {priceRow("Subtotal", (verifyUser?.prices.original).toFixed(4))}
                  {getTheTax()}
                  {priceRow("Shipping Price", (verifyUser?.prices.shipping).toFixed(4))}
                  <Divider />
                  <Grid container className="prices">
                    <Grid item>
                      <Typography variant="h6">Total</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6">
                        wETH {verifyUser?.prices.total.toFixed(4)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <ButtonBlue
                  props={{
                    variant: "contained",
                    fullWidth: true,
                    className: "pay-button",
                  }}
                  included={true}
                  onClick={mintTheCards}
                >
                  PAY {verifyUser?.prices.total.toFixed(4)} wETH For{" "}
                  {store.cart.offer?.numberOfTokens} Cards
                </ButtonBlue>
              </Grid>
            ) : (
              <>
                <Typography
                  variant="textMediumNormal"
                  component="p"
                  sx={{ padding: `${theme.spacing(3)} 0` }}
                >
                  You must verify yourself to mint!
                </Typography>
                <ButtonBlue
                  props={{ variant: "contained", fullWidth: true }}
                  included={true}
                  onClick={tryToVerifyUser}
                >
                  Verify yourself!
                </ButtonBlue>
              </>
            )}
          </Grid>
        )}
      </CryptoPaymentStyle>
    </>
  );
};

export default CryptoPayment;
