import { BillingAddress } from "../../../contexts/Store";
import RadioModel from "../../../models/radio";

export const INITIAL_FORM: BillingAddress = {
  email: "",
  firstName: "",
  lastName: "",
  street: "",
  country: "United States",
  state: "Alabama",
  city: "",
  postalCode: "",
  confirmMailingAddress: "yes"
};

export const CONFIRM_MAILING_ADDRESS_RADIO_OPTIONS: RadioModel[] = [
  { label: "Yes, my mailing address matches my billing address", value: "yes" },
  { label: "No, I have a different mailing address", value: "no" }
];
