import {
  Alert,
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import { FC, useEffect, useState } from "react";
import { BillingAddress, ShippingAddress } from "../../../contexts/Store";
import {
  SmartyInternationValidationDTO,
  SmartyUsValidationDTO,
} from "../../../dtos/SmartyRequestDTO";
import { SmartyVerificationMapper } from "../../../mappers/smartyMapper";
import { smartyInternationalValidateApi, smartyUsValidateApi } from "../../../utils/constants";
import theme from "../../../utils/theme";
import ButtonBlue from "../button-blue/Component";
import AddressValidationDialogStyle from "./Style";

type Props = {
  open: boolean;
  userAddress: BillingAddress | ShippingAddress;
  onClose: () => void;
  onSave: () => void;
  label: "shipping" | "billing";
};

type Validation = {
  text: string;
  type: "info" | "success" | "warning" | "error";
};

const AddressValidationDialog: FC<Props> = ({ open, onClose, userAddress, onSave, label }) => {
  const [validation, setValidation] = useState<Validation>({
    text: "",
    type: "info",
  });
  const [isLoading, setIsLoading] = useState(true);

  const handleConfirm = () => {
    onSave();
  };

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        if (userAddress.country == "United States") {
          const street = encodeURIComponent(
            `${userAddress.street} ${
              (userAddress as ShippingAddress)?.apartment
                ? (userAddress as ShippingAddress)?.apartment
                : ""
            }`
          );
          const { data } = await axios.get<SmartyUsValidationDTO[]>(
            `${smartyUsValidateApi}&street=${street}&city=${userAddress.city}&state=${userAddress.state}&zipcode=${userAddress.postalCode}&match=enchanced`
          );

          if (data.length === 0) {
            setValidation({
              text: "Your address is not found in database.",
              type: "error",
            });
            return;
          }

          const model = SmartyVerificationMapper(data[0], false);
          switch (model.isValidAddress) {
            case "error":
              setValidation({
                text: "Your address is not found in database.",
                type: "error",
              });
              break;
            case "warning":
              setValidation({
                text: "Your address is partialy found in database.",
                type: "warning",
              });
              break;
            case "success":
              setValidation({
                text: "Your address is found in database.",
                type: "success",
              });
              break;

            default:
              break;
          }
        } else {
          const { data } = await axios.get<SmartyInternationValidationDTO[]>(
            `${smartyInternationalValidateApi}&country=${userAddress.country}&address1=${
              userAddress.street
            }  ${
              (userAddress as ShippingAddress)?.apartment
                ? (userAddress as ShippingAddress)?.apartment
                : ""
            }&locality=${userAddress.city}&administrative_area=${userAddress.state}&postal_code=${
              userAddress.postalCode
            }`
          );
          if (data.length === 0) {
            setValidation({
              text: "Your address is not found in database.",
              type: "error",
            });
            return;
          }
          const model = SmartyVerificationMapper(data[0], true);
          switch (model.isValidAddress) {
            case "error":
              setValidation({
                text: "Your address is not found in database.",
                type: "error",
              });
              break;
            case "warning":
              setValidation({
                text: "Your address is partialy found in database.",
                type: "warning",
              });
              break;
            case "success":
              setValidation({
                text: "Your address is found in database.",
                type: "success",
              });
              break;
            default:
              break;
          }
        }
      } catch (error) {
        console.error(error);
        setValidation({
          text: "Validation failed",
          type: "error",
        });
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 400);
      }
    };
    if (open) {
      fetch();
    }
  }, [open]);

  return (
    <AddressValidationDialogStyle open={open}>
      <DialogTitle variant="h2">Verify your {label} address </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xxxs={12} container>
            <Grid item xxxs={12}>
              <Typography variant="textMediumSemibold" sx={{ paddingY: theme.spacing(1) }}>
                Summary:
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="textMediumNormal"
                sx={{
                  paddingBottom: theme.spacing(2),
                }}
              >
                {userAddress.street}
                {(userAddress as ShippingAddress)?.apartment
                  ? " " + (userAddress as ShippingAddress)?.apartment
                  : ""}
                {", "}
                {userAddress.city} {userAddress.postalCode} {userAddress.state},{" "}
                {userAddress.country}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xxxs={12}>
            {isLoading ? (
              <Box textAlign="center">
                <CircularProgress size={50} color="secondary" />
              </Box>
            ) : (
              <Alert severity={validation.type}>{validation.text}</Alert>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          flexWrap: "wrap",
          gap: theme.spacing(2),
          paddingX: theme.spacing(3),
        }}
      >
        <ButtonBlue
          onClick={handleCancel}
          props={{
            variant: "outlined",
            disabled: isLoading,
          }}
        >
          Cancel
        </ButtonBlue>

        <ButtonBlue
          onClick={handleConfirm}
          props={{
            variant: "contained",
            disabled: isLoading,
            sx: {
              padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
            },
          }}
        >
          Confirm address
        </ButtonBlue>
      </DialogActions>
    </AddressValidationDialogStyle>
  );
};

export default AddressValidationDialog;
