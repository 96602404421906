import React, { FC, ReactNode } from "react";
import { Grid, ListItem, SvgIcon, Typography } from "@mui/material";
import ListStyle from "./Style";
import { StarIcon } from "../../../utils/svgIcon";
import { PropertyModel } from "../../../models/offer";

interface Props {
  properties: PropertyModel[] | undefined;
}

const OffersList: FC<Props> = ({ properties }) => {
  const offerListItem = (property: PropertyModel, index: number): ReactNode => (
    <ListItem key={index}>
      <Grid container spacing={1} flexWrap="nowrap">
        <Grid item xxs={0.8} className="svg-grid">
          <SvgIcon component={StarIcon} />
        </Grid>
        <Grid item xxs={11.2}>
          <Typography className="quantity" variant="textSmallMedium">
            ({property.quantity}){" "}
          </Typography>
          <Typography className="offers-list-description" variant="textSmallMedium">
            {property.name}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );

  return (
    <ListStyle className="selected-package">
      {properties!.map((property, index) => offerListItem(property, index))}
    </ListStyle>
  );
};

export default OffersList;
