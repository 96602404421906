import { useCallback } from "react";
import PackageCounterModel from "../models/counter";
import { api } from "../utils/api";
import { counterEndpoint } from "../utils/constants";
import { DisplayTypeEnum, OfferModel } from "../models/offer";
import { getRandomNumber } from "../utils/utils";

const useCounterService = () => {
  const updateOffersWithRemaining = useCallback(async (offers: OfferModel[]) => {
    try {
      const packageCounters = await getPackageCounters();
      const updatedOffers = updateRemaining(offers, packageCounters);
      return updatedOffers;
    } catch (error) {
      console.error(error);
    }
    return offers;
  }, []);

  const getPackageCounters = useCallback(async (): Promise<PackageCounterModel> => {
    const url = `${counterEndpoint}/packages_left.json?v=${getRandomNumber()}`;
    const { data } = await api.get<PackageCounterModel>(url);
    return data;
  }, []);

  const updateRemaining = (offers: OfferModel[], remainingObject: PackageCounterModel) => {
    return offers.map((offer) => {
      const key = DisplayTypeEnum[offer.displayType].toLowerCase();
      if (remainingObject[key as keyof PackageCounterModel] !== undefined)
        offer.remaining = remainingObject[key as keyof PackageCounterModel];
      return offer;
    });
  };

  return { updateOffersWithRemaining };
};

export default useCounterService;
