import { Alert, Grid, InputAdornment, Typography } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { useStoreContext } from "../../../hooks/Contexts";
import {
  ActiveStepModel,
  ContainerMaxWidth,
  ShippingAddress,
  StepModel,
  StoreModel,
} from "../../../contexts/Store";
import TextInput from "../../form/text-input/Component";
import ButtonBlue from "../../form/button-blue/Component";
import { isValid } from "../../../utils/validation";
import CountryInput from "../../form/country-input/Component";
import StateInput from "../../form/state-input/Component";
import { ExploreOutlined } from "@mui/icons-material";
import ShippingAddressFormStyle from "./Style";
import { INITIAL_FORM } from "./StaticData";
import { componentTracking, hasPhysicalItem } from "../../../utils/utils";
import StreetInput from "../../form/street-input/Component";
import AddressValidationDialog from "../../form/address-validation-dialog/Component";

const ShippingAddressForm: FC = (): JSX.Element => {
  const { store, setStore } = useStoreContext();
  const formInitialize = useMemo((): ShippingAddress => {
    if (store.cart.addresses.billing.confirmMailingAddress === "yes") {
      return store.cart.addresses.shipping;
    }
    let initForm = INITIAL_FORM;
    if (store.cart.paymentMethod === "Crypto" && store.cart.kycForm.postalCode) {
      initForm = {
        ...initForm,
        ...store.cart.addresses.shipping,
        postalCode: store.cart.kycForm.postalCode,
      };
    }
    return initForm;
  }, [store.cart.addresses.shipping]);

  const [shippingAddress, setShippingAddress] = useState<ShippingAddress>(formInitialize);
  const [completed, setCompleted] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>("");
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);

  const backHandler = (): ActiveStepModel => {
    if (store.cart.paymentMethod === "CreditCard") {
      return {
        component: store.flowControl.steps.billingAddressForm.component,
        title: "Checkout",
      };
    }

    return {
      component: store.flowControl.steps.kycForm.component,
      title: "Checkout",
    };
  };

  useEffect(() => {
    componentTracking("checkout", {
      checkout_view: "Enter Shipping Address",
    });

    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        backStep: backHandler(),
      },
      containerMaxWidth: ContainerMaxWidth.DEFAULT,
    }));
  }, []);

  const onChange = (property: string, value: string): void => {
    setShippingAddress((prevState) => ({
      ...prevState,
      [property]: value,
    }));
  };

  useEffect(() => {
    setCompleted(
      isValid(INITIAL_FORM, shippingAddress, [
        "firstName",
        "lastName",
        "country",
        "street",
        "city",
        "state",
        "postalCode",
      ])
    );
  }, [shippingAddress]);

  const getActiveStep = (storePrevState: StoreModel): StepModel => {
    if (storePrevState.cart.offer && hasPhysicalItem(storePrevState.cart.offer)) {
      return storePrevState.flowControl.steps.physicalItemSizes;
    }
    return storePrevState.cart.paymentMethod === "Crypto"
      ? storePrevState.flowControl.steps.cryptoPayment
      : storePrevState.flowControl.steps.creditCardPayment;
  };

  const openValidationDialog = () => {
    if (!completed) {
      setFormError("Please fill in the required fields.");
      return;
    }

    setDialogIsOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogIsOpen(false);
  };

  const save = () => {
    setStore((prevState) => ({
      ...prevState,
      cart: {
        ...prevState.cart,
        addresses: {
          ...prevState.cart.addresses,
          shipping: shippingAddress,
        },
      },
      flowControl: {
        ...prevState.flowControl,
        activeStep: {
          ...getActiveStep(prevState),
          title: "Checkout",
        },
      },
    }));
  };

  return (
    <ShippingAddressFormStyle>
      <Grid container>
        <Grid item xxxs={12}>
          <Typography variant="h1">Enter Shipping Address</Typography>
        </Grid>
        <Grid item xxxs={12} sx={{ my: 3 }} textAlign="center">
          <Typography variant="textMediumSemibold" className="description">
            Please provide the address where you'd like your physical trading card(s) and sneakers
            to be delivered.
          </Typography>
        </Grid>
        <Grid>
          <Typography variant="h6" component="p">
            Shipping Address
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 0 }} spacing={3}>
        <Grid item xxxs={12} xs={6}>
          <TextInput
            label="First Name"
            property="firstName"
            value={shippingAddress.firstName}
            onChange={onChange}
            props={{ autoFocus: true }}
          />
        </Grid>
        <Grid item xxxs={12} xs={6}>
          <TextInput
            label="Last Name"
            property="lastName"
            value={shippingAddress.lastName}
            onChange={onChange}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 3 }}>
        <Grid item xxxs={12}>
          <CountryInput
            label="Select Country ..."
            property="country"
            onChange={onChange}
            value={shippingAddress.country}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 3 }}>
        <Grid item xxxs={12}>
          <StreetInput
            country={shippingAddress.country}
            onChange={onChange}
            value={shippingAddress.street}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 3 }}>
        <Grid item xxxs={12}>
          <TextInput
            label="Apartment, suite, etc. (optinal)"
            property="apartment"
            value={shippingAddress.apartment}
            onChange={onChange}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 0 }} spacing={3}>
        <Grid item xxxs={12} xs={6}>
          <TextInput
            label="City"
            property="city"
            value={shippingAddress.city}
            onChange={onChange}
          />
        </Grid>
        <Grid item xxxs={12} xs={6}>
          <StateInput
            label="Select State / Province ..."
            property="state"
            value={shippingAddress.state}
            onChange={onChange}
            country={shippingAddress.country}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 3 }}>
        <Grid item xxxs={12}>
          <TextInput
            label="ZIP Code / Postal Code"
            property="postalCode"
            value={shippingAddress.postalCode}
            onChange={onChange}
            props={{
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <ExploreOutlined />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 3 }}>
        <Grid item xxxs={12}>
          <TextInput
            label="Mobile Phone (optional)"
            property="phone"
            value={shippingAddress.phone}
            onChange={onChange}
          />
        </Grid>
      </Grid>
      {formError && (
        <Grid container sx={{ mt: 3 }}>
          <Grid item xxxs={12}>
            <Alert severity="error">{formError}</Alert>
          </Grid>
        </Grid>
      )}
      <Grid container sx={{ mt: 3 }}>
        <Grid item xxxs={12}>
          <ButtonBlue
            props={{
              variant: "contained",
              fullWidth: true,
            }}
            onClick={openValidationDialog}
          >
            Continue
          </ButtonBlue>
        </Grid>
      </Grid>
      <AddressValidationDialog
        open={dialogIsOpen}
        onClose={handleCloseDialog}
        onSave={save}
        userAddress={shippingAddress}
        label="shipping"
      />
    </ShippingAddressFormStyle>
  );
};

export default ShippingAddressForm;
