import React, { FC, useEffect, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import QuantitySelectorStyle from "./Style";
import theme from "../../utils/theme";
import { useStoreContext } from "../../hooks/Contexts";
import { GreenCheckIcon, CircleIcon, DecrementIcon, IncrementIcon } from "../../utils/svgIcon";
import { OfferModel } from "../../models/offer";
import { maxMintableTokens } from "../../utils/constants";
import useContractService from "../../services/Contract";

interface Props {
  offer: OfferModel;
}

const QuantitySelector: FC<Props> = ({ offer }) => {
  const { store, setStore } = useStoreContext();
  const { getTokenBalanceOfByWalletAddress } = useContractService();
  const [tokenQuantity, setTokenQuantity] = useState<number>(1);
  const [mintedBalance, setMintedBalance] = useState<number>(0);

  const exceededMaxMint = maxMintableTokens < tokenQuantity;

  const updateQuantity = (quantity: number): void => {
    setTokenQuantity(quantity);
  };

  const addToCart = () => {
    setStore((prevState) => ({
      ...prevState,
      cart: {
        ...prevState.cart,
        offer: {
          ...offer,
          numberOfTokens: tokenQuantity,
          quantity: { max: offer.quantity!.max, value: tokenQuantity },
        },
      },
    }));
  };

  useEffect(() => {
    (async () => {
      const balance = await getTokenBalanceOfByWalletAddress(
        store.cart.user.wallet,
        store.web3Auth.web3AuthProvider
      );
      if (!balance) return;
      setMintedBalance(balance);
    })();
  }, [store.cart.user.wallet, store.web3Auth.web3AuthProvider]);

  const handleIncrement = () => {
    updateQuantity(Math.min(tokenQuantity + 1, offer.quantity!.max!));
  };

  const handleDecrement = () => {
    updateQuantity(Math.max(1, tokenQuantity - 1));
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Math.min(4, Math.max(1, parseInt(event.target.value) || 1));
    updateQuantity(newValue);
  };

  const checkIfOfferSelected = () => store.cart.offer?.packageType === offer.packageType;

  const AvailabilityBox = () => (
    <Box>
      {exceededMaxMint ? (
        <Typography variant="h6" component="span" sx={{ color: theme.palette.color2.main }}>
          Not available
        </Typography>
      ) : (
        <Typography variant="h6" component="span" sx={{ color: theme.palette.color2.main }}>
          Available
        </Typography>
      )}
    </Box>
  );

  return (
    <Box sx={{ filter: `grayscale(${exceededMaxMint ? 1 : 0})` }}>
      <Box
        className="availability-container"
        sx={{
          minHeight: "33px",
          justifyContent: "center",
        }}
      >
        <AvailabilityBox />
      </Box>
      <QuantitySelectorStyle>
        <Box>
          <Box className="title-stripe" sx={{ backgroundColor: "#000" }}>
            <Box className="selected-checkbox">
              {checkIfOfferSelected() ? <GreenCheckIcon /> : <CircleIcon />}
            </Box>
            <Box className="titles">
              <Typography
                variant="textMediumSemibold"
                component="p"
                className="offer-header"
                dangerouslySetInnerHTML={{ __html: offer.header }}
              />
              <Typography
                variant="textMediumSemibold"
                component="p"
                className="offer-package-title"
              >
                {offer.packageTitle}
              </Typography>
            </Box>
          </Box>
          <Box className="select-quantity-title">
            <Typography variant="h6">Select your quantity</Typography>
            <Typography variant="h6" color={theme.palette.color2.main}>
              {`Buy 1 - ${offer.quantity?.max} cards`}
            </Typography>
          </Box>
          <Grid container className="quantity-selector-grid">
            <Grid item>
              <Button onClick={handleDecrement}>
                <DecrementIcon />
              </Button>
            </Grid>
            <Grid item>
              <TextField
                className="quantity-input"
                variant="outlined"
                value={tokenQuantity}
                onChange={handleOnChange}
                InputProps={{
                  style: {
                    fontFamily: "SF UI Display Semibold",
                    fontSize: "36px",
                    padding: "0",
                    height: "50px",
                  },
                }}
              />
            </Grid>
            <Grid item>
              <Button onClick={handleIncrement}>
                <IncrementIcon />
              </Button>
            </Grid>
          </Grid>
          {!exceededMaxMint && mintedBalance + tokenQuantity <= maxMintableTokens && (
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Button onClick={addToCart}>
                <Typography
                  variant="textUppercase"
                  sx={{
                    color: theme.palette.color20.main,
                    textDecoration: "underline",
                  }}
                >
                  Add to cart
                </Typography>
              </Button>
            </Box>
          )}
          {!exceededMaxMint && mintedBalance + tokenQuantity > maxMintableTokens && (
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Typography variant="textSmallMedium" color={theme.palette.color2.main}>
                You've reached the maximum number of cards you can mint (250 / wallet).
              </Typography>
            </Box>
          )}
        </Box>
      </QuantitySelectorStyle>
    </Box>
  );
};

export default QuantitySelector;
