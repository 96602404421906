import { Box, styled } from "@mui/material";
import theme from "../../../utils/theme";

const OffersStyle = styled(Box)(() => ({
  h1: {
    textAlign: "center",
    padding: `${theme.spacing(4)} 0px`,
    borderBottom: `1px solid ${theme.palette.color17.main}`,
  },
  h3: {
    [theme.breakpoints.down("xxs")]: {
      fontSize: "25px !important",
      lineHeight: "30px!important",
    },
    span: {
      [theme.breakpoints.down("xxs")]: {
        fontSize: "25px !important",
        lineHeight: "30px!important",
      },
    },
  },
  ".header-description": {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down("xxs")]: {
      padding: `${theme.spacing(1)} 0`,
    },
  },
  ".main-grid-container": {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  ".offer-grid": {
    width: "100%",
    [theme.breakpoints.down("xxs")]: {
      paddingTop: theme.spacing(1),
    },
  },
  ".one-transaction-text": {
    textAlign: "center",
    marginTop: "30px",
    animation: "effect 1.5s linear infinite",
    "@keyframes effect": {
      "0%": {
        color: theme.palette.color11.main,
      },
      "100%": {
        color: theme.palette.color2.main,
      },
    },
  },
  ".availability-container": {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  ".offer-type-label": {
    textTransform: "uppercase",
    color: theme.palette.color20.main,
    paddingLeft: "2px",
  },
  ".offer-type-box": {
    border: `1px solid #0075ff33`,
    borderRadius: "20px",
    backgroundColor: "#0075ff33",
    padding: "6px 10px",
  },
  ".double-arrow-svg-box": {
    textAlign: "center",
    marginTop: "20px",
  },
  ".bonus-container": {
    borderRadius: "5px",
    minHeight: "42px",
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    maxWidth: "52px",
    padding: "2px",
  },
}));

export default OffersStyle;
