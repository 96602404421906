import { styled, Box } from "@mui/material";
import theme from "../../utils/theme";

const QuantitySelectorStyle = styled(Box)(() => ({
  position: "relative",
  padding: "3px",
  border: `3px dashed ${theme.palette.primary.light}`,
  "& hr": {
    borderBottom: `1px solid ${theme.palette.color3.main}`,
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: 0,
  },
  ".buy-now-button.Mui-disabled": {
    color: theme.palette.color1.main,
  },
  ".quantity-selector-grid": {
    marginTop: theme.spacing(1),
    justifyContent: "center",
    margin: "20px 0",
  },
  ".quantity-input": {
    maxWidth: "100px",
    "& input": {
      textAlign: "center",
    },
  },
  ".selected-checkbox": {
    position: "absolute",
    right: "10px",
    top: "10px",
  },
  ".title-stripe": {
    minHeight: "106px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    padding: "5px 0px",
  },
  ".select-quantity-title": {
    textAlign: "center",
    marginTop: "20px",
  },
  ".offer-name": {
    textTransform: "uppercase",
    color: theme.palette.color19.main,
    maxWidth: "185px",
    fontSize: "18px",
  },
  ".titles": {
    display: "flex",
    maxWidth: "256px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    textTransform: "uppercase",
    ".offer-header": {
      color: theme.palette.color19.main,
      maxWidth: "237px",
      fontSize: "20px",
      lineHeight: "21px",
      span: {
        textTransform: "none",
      },
    },
    ".offer-package-title": {
      marginTop: theme.spacing(1),
      color: theme.palette.color1.main,
      fontSize: "15px",
      lineHeight: "15.75px",
    },
  },
}));

export default QuantitySelectorStyle;
