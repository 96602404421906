import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import TransactionLoaderStyle from './Style';
import { FC } from 'react';
import { useStoreContext } from '../../hooks/Contexts';
import ButtonBlue from '../form/button-blue/Component';

interface PropsModel {
  type: 'Transaction' | 'Simple Loading';
}
const TransactionLoader: FC<PropsModel> = ({ type }) => {
  const { store, setStore } = useStoreContext();

  const backToPaymentHandler = () => {
    setStore((prevState) => ({
      ...prevState,
      cart: {
        ...prevState.cart,
        isTransactionInProgress: false,
        creditCardTransactionErrorMessage: null
      }
    }));
  };

  const loadingTextSection = () => {
    if (type === 'Simple Loading') {
      return <Typography>Loading...</Typography>;
    } else {
      return (
        <Box>
          {store.cart.paymentMethod === 'CreditCard' ? (
            <Typography>
              {store.cart.creditCardTransactionErrorMessage === null ? "Transaction in progress. Please don't close the window!" : (
                <>
                  {store.cart.creditCardTransactionErrorMessage && (
                    <>
                      The following error occurred: <br />
                      {store.cart.creditCardTransactionErrorMessage} <br />
                    </>
                  )}
                  Please try again or please use a different card. <br /><br ></br>
                  <ButtonBlue onClick={backToPaymentHandler} props={{ variant: "contained" }}>Back to payment screen</ButtonBlue>
                </>
              ) }
            </Typography>
          ) : (
            <Typography>
              Transaction in progress.{' '}
              {store.cart.polygonScanHash && (
                <Typography>
                  {' '}
                  You can check it on{' '}
                  <a
                    href={
                      'https://polygonscan.com/tx/' + store.cart.polygonScanHash
                    }
                    target="blank"
                  >
                    Polygonscan!
                  </a>{' '}
                </Typography>
              )}
            </Typography>
          )}{' '}
        </Box>
      );
    }
  };

  return (
    <TransactionLoaderStyle>
      <Grid container spacing={3}>
        <Grid item xxxs={12}>
          {loadingTextSection()}
        </Grid>
        <Grid item xxxs={12}>
          {' '}
          {!store.cart.creditCardTransactionErrorMessage ? (<CircularProgress size={100} color="secondary" />) : <></>}
        </Grid>
      </Grid>
    </TransactionLoaderStyle>
  );
};

export default TransactionLoader;
