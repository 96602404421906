import { Box, styled } from "@mui/material";
import theme from "../../../utils/theme";

const CreditCardPaymentStyle = styled(Box)(() => ({
  h1: {
    padding: `${theme.spacing(4)} 0px`,
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.color17.main}`,
  },
  ".description": {
    [theme.breakpoints.up("xxxs")]: {
      padding: `${theme.spacing(2)} 0`,
    },
    [theme.breakpoints.up("sm")]: {
      padding: `${theme.spacing(3)} 0`,
    },
    fontSize: "20px",
  },
  ".payment-container": {
    position: "relative",
  },
  ".payment-form-overlay": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "7px",
    marginTop: "15px",

    h6: {
      fontSize: "30px",
    },
  },
  ".prices": {
    padding: `${theme.spacing(1)} 0`,
    justifyContent: "space-between",
  },
  "& hr": {
    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    margin: `${theme.spacing(3)} 0`,
    [theme.breakpoints.down("xxs")]: {
      margin: `${theme.spacing(2)} 0`,
    },
  },
  "& input": {
    borderRadius: 0,
    border: "none",
    padding: "12.5px 14px",
    height: "1.8500rem",
    outline: "none",
  },
  ".collect-div": {
    padding: "1px",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.secondary.dark}`,
  },
  ".total-price-section": {
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("xxs")]: {
      paddingBottom: theme.spacing(2),
    },
  },
}));

export default CreditCardPaymentStyle;
