import { List, styled } from "@mui/material";
import theme from "../../../utils/theme";

const ListStyle = styled(List)(() => ({
  padding: "8px 16px",
  overflowY: "auto",
  ".svg-grid": {
    paddingTop: "11px !important",
  },
  "& li": {
    padding: 0,
  },
  /* Scrollbar styling */
  "&::-webkit-scrollbar": {
    width: "0px",
  },
  "& hr": {
    borderBottom: `1px solid ${theme.palette.color3.main}`,
  },

  ".offer-list-description": {
    lineHeight: "20px",
    whiteSpace: "pre-line",
    textTransform: "capitalize",
  },
  ".show-p-on-disable": {
    display: "none",
  },
  ".disabled": {
    ".inactive-p-when-disabled": {
      textDecoration: "line-through",
      color: theme.palette.color14.main,
    },
    ".show-p-on-disable": {
      display: "block",
      color: theme.palette.color2.main,
    },
  },
  ".offers-list-description": {
    lineHeight: "15.4px",
    textTransform: "uppercase",
  },
}));

export default ListStyle;
