import { Check, Close, NavigateBefore } from '@mui/icons-material';
import { Badge, Box, Button, Container, Grid, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import HeaderStyle from './Style';
import { useStoreContext } from '../../../hooks/Contexts';
import { ActiveStepModel, initialState } from '../../../contexts/Store';
import { collectTrumpCardsSite } from '../../../utils/constants';

const Header: FC = (): JSX.Element => {
  const { store, setStore } = useStoreContext();
  const [checkedSteps, setCheckedSteps] = useState<string[]>([]);

  useEffect(() => {
    const activeIndex = store.flowControl.highlightedSteps.findIndex((step) => step === store.flowControl.activeStep.title);
    const checked: string[] = [];
    store.flowControl.highlightedSteps.forEach((step, index) => {
      if (index < activeIndex) {
        checked.push(step);
      }
    });
    setCheckedSteps(checked);
  }, [store.flowControl]);

  const stepView = (step: string, order: number) => (
    <Grid
      item
      className={`step ${
        step === store.flowControl.activeStep.title && 'active'
      }`}
      key={order}
    >
      <Box>
        <Badge
          badgeContent={
            <Typography variant="textRegularNormal">{checkedSteps.includes(step) ? <Check sx={{ position: "absolute", top: 0, left: 0, height: "20px", width: "20px"}} /> : order}</Typography>
          }
        />
        <Typography className="title" variant="textSmallNormal">
          {step}
        </Typography>
      </Box>
    </Grid>
  );

  const backHander = useCallback(() => {
    if (!store.flowControl.backStep) {
      window.location.href = collectTrumpCardsSite as string;
      return;
    }
    if (store.flowControl.backStep.title === 'Get Started') {
      store.web3Auth.web3AuthInstance?.loginModal.closeModal();
      if (store.web3Auth.web3AuthInstance?.connected) {
        store.web3Auth.web3AuthInstance?.logout();
      }
      setStore((prevState) => ({
        ...prevState,
        web3Auth: initialState.web3Auth,
        flowControl: {
          ...prevState.flowControl,
          activeStep: prevState.flowControl.backStep as ActiveStepModel,
        },
      }));
    } else {
      setStore((prevState) => ({
        ...prevState,
        flowControl: {
          ...prevState.flowControl,
          activeStep: prevState.flowControl.backStep as ActiveStepModel,
        },
      }));
    }
  }, [store]);

  const handleClose = () => {
    window.location.href = collectTrumpCardsSite as string;
  };

  return (
    <HeaderStyle>
      <Container>
        <Grid container justifyContent="space-between">
          <Grid item xxxs={1} xs={2} className="back-section">
            <Button
              onClick={backHander}
              disabled={store.cart.isTransactionInProgress || store.cart.threeDSInProgress}
              sx={{
                opacity: store.cart.isTransactionInProgress || store.cart.threeDSInProgress ? 0.6 : 1,
                minWidth: 'unset',
              }}
            >
              <NavigateBefore />{' '}
              <Typography
                variant="textSmallNormal"
                sx={{ display: { xxxs: 'none', xs: 'block' } }}
              >
                Back
              </Typography>
            </Button>
          </Grid>
          <Grid item xxxs={10} xs={8} className="steps-section">
            <Grid
              container
              justifyContent="center"
              className="step-section-grid-container"
              columnSpacing={{ xxxs: 2, xs: 5 }}
            >
              {store.flowControl.highlightedSteps.map((step, index) =>
                stepView(step, index + 1)
              )}
            </Grid>
          </Grid>
          <Grid item xxxs={1} xs={2} className="close-section">
            <Button
              onClick={handleClose}
              disabled={store.cart.isTransactionInProgress || store.cart.threeDSInProgress}
              sx={{
                opacity: store.cart.isTransactionInProgress || store.cart.threeDSInProgress ? 0.6 : 1,
                minWidth: 'unset',
              }}
            >
              <Typography
                variant="textSmallNormal"
                sx={{ display: { xxxs: 'none', xs: 'block' } }}
              >
                {' '}
                Close{' '}
              </Typography>
              <Close />
            </Button>
          </Grid>
        </Grid>
      </Container>
    </HeaderStyle>
  );
};

export default Header;
