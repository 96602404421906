import React, { ReactNode, useEffect, useState } from "react";
import PhyisicalItemSizesStyle from "./Style";
import { Box, Grid, Typography } from "@mui/material";
import { useStoreContext } from "../../../hooks/Contexts";
import PhysicalItemSizeInput from "../../form/physical-item-size-input/Component";
import { FeatureModel, PropertyModel } from "../../../models/offer";
import { ContainerMaxWidth } from "../../../contexts/Store";
import ButtonBlue from "../../form/button-blue/Component";
import { componentTracking } from "../../../utils/utils";

const PhysicalItemSizes = () => {
  const { store, setStore } = useStoreContext();
  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    componentTracking('checkout', {
      checkout_view: 'Select Shoe Size',
    });

    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        backStep: {
          component: prevState.flowControl.steps.shippingAddressForm.component,
          title: "Checkout",
        },
      },
      containerMaxWidth: ContainerMaxWidth.DEFAULT,
    }));
  }, []);

  const getPropertiesPhysicalItemLength = (): number => {
    return store.cart
      .offer!.properties!.filter((property) => property.physicalItem && property.physicalItem.sizes)
      .reduce((memo, property) => {
        return memo + property.quantity;
      }, 0);
  };

  const getBonusesPhysicalItemLength = (): number => {
    return store.cart
      .offer!.features!.filter((bonus) => bonus.physicalItem && bonus.physicalItem.sizes)
      .reduce((memo, bonus) => {
        return memo + bonus.quantity;
      }, 0);
  };

  const getPhysicalItemLength = () => {
    return getPropertiesPhysicalItemLength() + getBonusesPhysicalItemLength();
  };

  const onChange = (name: string, index: number, sizeValue: string) => {
    setStore((prevState) => {
      const properties = store.cart.offer!.properties?.map((property) => {
        if (property.name === name) {
          return {
            ...property,
            physicalItem: {
              ...property.physicalItem,
              selectedSizes: {
                ...property.physicalItem?.selectedSizes,
                [`${name} pair ${index}`]: sizeValue,
              },
            },
          };
        }
        return property;
      });
      const bonuses = store.cart.offer!.features?.map((bonus) => {
        if (bonus.name === name) {
          return {
            ...bonus,
            physicalItem: {
              ...bonus.physicalItem,
              selectedSizes: {
                ...bonus.physicalItem?.selectedSizes,
                [`${name} pair ${index}`]: sizeValue,
              },
            },
          };
        }
        return bonus;
      });
      return {
        ...prevState,
        cart: {
          ...prevState.cart,
          offer: { ...prevState.cart.offer!, properties, features: bonuses },
        },
      };
    });
  };

  useEffect(() => {
    const properties = store.cart
      .offer!.properties?.filter((property) => property.physicalItem && property.physicalItem.sizes)
      .reduce((acc, curr) => {
        return acc + Object.keys(curr.physicalItem!.selectedSizes).length;
      }, 0);
    const bonuses = store.cart
      .offer!.features?.filter((bonus) => bonus.physicalItem && bonus.physicalItem.sizes)
      .reduce((acc, curr) => {
        return acc + Object.keys(curr.physicalItem!.selectedSizes).length;
      }, 0);
    if (properties! + bonuses! !== getPhysicalItemLength()) {
      setCompleted(false);
      return;
    }
    setCompleted(true);
  }, [store.cart.offer!.features, store.cart.offer!.properties]);

  const dropDownSelectionByQuantity = (value: PropertyModel | FeatureModel): ReactNode[] => {
    const dropdowns: ReactNode[] = [];
    for (let i = 1; i <= value.quantity; i++) {
      const key = `${value.name} pair ${i}`;
      const selected = value.physicalItem!.selectedSizes[key];
      dropdowns.push(
        <Box className="physical-item-selection" key={i}>
          <PhysicalItemSizeInput
            label={`Select Size - Pair #${i}`}
            options={value.physicalItem?.sizes?.options ?? {}}
            selectProps={{
              onChange: (e) => onChange(value.name, i, e.target.value as string),
              value: selected ?? "",
            }}
          />
        </Box>
      );
    }
    return dropdowns;
  };

  const toPayment = () => {
    setStore((prevState) => ({
      ...prevState,
      flowControl: {
        ...prevState.flowControl,
        activeStep: {
          component:
            prevState.cart.paymentMethod === "CreditCard"
              ? prevState.flowControl.steps.creditCardPayment.component
              : prevState.flowControl.steps.cryptoPayment.component,
          title: "Checkout",
        },
      },
    }));
  };

  return (
    <PhyisicalItemSizesStyle>
      <Grid container>
        <Grid item xxxs={12}>
          <Typography variant="h1">Select Shoe Sizes</Typography>
        </Grid>
        <Grid item xxxs={12} sx={{ my: 3 }} textAlign="center">
          <Typography variant="textMediumSemibold" className="description">
            Your package includes {getPhysicalItemLength()} sneaker
            {getPhysicalItemLength() > 1 ? "s" : ""}. Please select your size. <br />
            <span className="atention-text">
              NOTICE: All selections are final so please choose carefully.
            </span>
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {store.cart.offer &&
          store.cart.offer
            .properties!.filter((property) => property.physicalItem && property.physicalItem.sizes)
            .map((property, index) => {
              return (
                <Grid item xxxs={12} xs={6} key={`${index}-property`}>
                  <Box className="physical-item">
                    <Box className="physical-item-details">
                      <img src={property.image} alt={property.image} />
                      <Typography
                        variant="textMediumSemibold"
                        className="physical-item-description"
                      >
                        {property.name}
                      </Typography>
                      <Box>
                        <Typography
                          variant="textRegularNormal"
                          className="physical-item-select-size"
                          component="p"
                        >
                          Select your size below:
                        </Typography>
                        {dropDownSelectionByQuantity(property)}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
        {store.cart.offer &&
          store.cart.offer
            .features!.filter((bonus) => bonus.physicalItem && bonus.physicalItem.sizes)
            .map((bonus, index) => {
              return (
                <Grid item xxxs={12} xs={6} key={`${index}-feature`}>
                  <Box className="physical-item">
                    <Box className="physical-item-details">
                      <img src={bonus.image} alt={bonus.image} />
                      <Typography
                        variant="textMediumSemibold"
                        className="physical-item-description"
                      >
                        {bonus.name}
                      </Typography>
                      <Box>
                        <Typography
                          variant="textRegularNormal"
                          className="physical-item-select-size"
                          component="p"
                        >
                          Select your size below:
                        </Typography>
                        {dropDownSelectionByQuantity(bonus)}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
      </Grid>
      <Grid container>
        <ButtonBlue
          props={{
            variant: "contained",
            fullWidth: true,
            className: "continue-button",
            disabled: !completed,
          }}
          onClick={toPayment}
        >
          Continue to Payment
        </ButtonBlue>
      </Grid>
    </PhyisicalItemSizesStyle>
  );
};

export default PhysicalItemSizes;
