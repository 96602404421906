import { taxes, Tax } from "../utils/taxes";

const useTaxService = () => {

  const getByPostalCode = (postalCode: keyof Tax): number => {
    const existTax = taxes[postalCode];
    if (!existTax) return 0;

    return Number(existTax);
  };

  return { getByPostalCode };
};

export default useTaxService;
