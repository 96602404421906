import { toast } from "react-toast";
import { useStoreContext } from "../hooks/Contexts";
import { useRecaptchaVerify } from "../hooks/Util";
import { api } from "../utils/api";
import { startKycEndpoint } from "../utils/constants";

const useKYCService = () => {
  const verifyCaptcha = useRecaptchaVerify();
  const { store } = useStoreContext();

  const getAccessTokenForKYC = async (): Promise<string> => {
    try {
      const recaptchaToken = await verifyCaptcha();
      const response = await api.post(startKycEndpoint as string, {
        email: store.cart.kycForm.email,
        token: recaptchaToken,
      });
      if (response.status !== 200) {
        throw new Error("Failed to fetch");
      }
      return response.data.accessToken.token;
    } catch (error) {
      //@ts-ignore
      toast.error(error?.message);
      //@ts-ignore
      return error?.message;
    }
  };
  return { getAccessTokenForKYC };
};

export default useKYCService;
