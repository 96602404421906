import { Box, styled } from '@mui/material';
import theme from '../../../utils/theme';

const CryptoPaymentStyle = styled(Box)(() => ({
  h1: {
    padding: `${theme.spacing(4)} 0px`,
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.color17.main}`
  },
  '.prices': {
    padding: `${theme.spacing(1)} 0`,
    justifyContent: 'space-between',
  },
  '& hr': {
    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    margin: `${theme.spacing(3)} 0`,
  },
}));

export default CryptoPaymentStyle;
