import { Box, styled } from "@mui/material";
import theme from "../../../utils/theme";

const KycFormStyle = styled(Box)(() => ({
  h1: {
    padding: `${theme.spacing(4)} 0px`,
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.color17.main}`
  },
  ".MuiButton-root": {
    "&.Mui-disabled": {
      color: theme.palette.color1.main
    }
  }
}));

export default KycFormStyle;
