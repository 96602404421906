import { Web3Auth, Web3AuthOptions } from "@web3auth/modal";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import { useCallback } from "react";
import { chainId, rpcTarget, web3authClientId, web3authNetwork } from "../utils/constants";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { ethers } from "ethers";
import { MetamaskAdapter } from "@web3auth/metamask-adapter";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";

const useWeb3Auth = () => {
  const initialize = useCallback(async () => {
    const chainConfig = {
      chainNamespace: CHAIN_NAMESPACES.EIP155,
      chainId: ethers.toQuantity(chainId as string),
      rpcTarget: rpcTarget!,
    };
    const provider = new EthereumPrivateKeyProvider({
      config: {
        chainConfig,
      },
    });

    const options: Web3AuthOptions = {
      clientId: web3authClientId as string,
      web3AuthNetwork: web3authNetwork,
      privateKeyProvider: provider,
      enableLogging: false,
      chainConfig: chainConfig,
      uiConfig: {
        mode: "light",
        logoLight: window.location.origin + "/DJT-portrait.png",
        logoDark: window.location.origin + "/DJT-portrait.png",
        appName: "Trump Digital Trading Cards",
        loginGridCol: 3,
        uxMode: "redirect",
      },
    };
    const web3Auth = new Web3Auth(options);

    const metamaskAdapter = new MetamaskAdapter({
      clientId: ethers.toQuantity(chainId as string),
      sessionTime: 3600, // 1 hour in seconds
      web3AuthNetwork: web3authNetwork,
      chainConfig: {
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: ethers.toQuantity(chainId as string),
        rpcTarget: rpcTarget!,
      },
    });

    const openloginAdapter = new OpenloginAdapter({
      clientId: ethers.toQuantity(chainId as string),
      sessionTime: 3600, // 1 hour in seconds
      web3AuthNetwork: web3authNetwork,
      chainConfig: {
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: ethers.toQuantity(chainId as string),
        rpcTarget: rpcTarget!,
      },
      adapterSettings: {
        redirectUrl: window.location.href,
      },
    });

    web3Auth.configureAdapter(metamaskAdapter);
    web3Auth.configureAdapter(openloginAdapter);

    await web3Auth.initModal({
      modalConfig: {
        [WALLET_ADAPTERS.OPENLOGIN]: {
          label: "openlogin",
          loginMethods: {
            farcaster: {
              name: "farcaster",
              showOnModal: false,
            },
            apple: {
              name: "facebook",
              showOnModal: false,
            },
            reddit: {
              name: "reddit",
              showOnModal: false,
            },
            line: {
              name: "line",
              showOnModal: false,
            },
            github: {
              name: "github",
              showOnModal: false,
            },
            wechat: {
              name: "wechat",
              showOnModal: false,
            },
            twitter: {
              name: "twitter",
              showOnModal: false,
            },
            kakao: {
              name: "kakao",
              showOnModal: false,
            },
            linkedin: {
              name: "linkedin",
              showOnModal: false,
            },
            weibo: {
              name: "weibo",
              showOnModal: false,
            },
            google: {
              name: "google",
              showOnModal: false,
            },
            discord: {
              name: "discord",
              showOnModal: false,
            },
            twitch: {
              name: "twitch",
              showOnModal: false,
            },
            facebook: {
              name: "facebook",
              showOnModal: false,
            },
            email_passwordless: {
              name: "email_passwordless",
              showOnModal: true,
            },
            sms_passwordless: {
              name: "sms_passwordless",
              showOnModal: false,
            },
          },
        },
        [WALLET_ADAPTERS.TORUS_EVM]: {
          label: "torus",
          showOnModal: false,
        },
        [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
          label: "walletc",
          showOnModal: false,
        },
      },
    });
    return web3Auth;
  }, []);

  return { initialize };
};

export default useWeb3Auth;
