import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { FC } from "react";
import RadioModel from "../../../models/radio";

type Props = {
  property: string;
  value: string;
  onChange: (property: string, value: string) => void;
  options: RadioModel[];
}

const RadioGroupInput: FC<Props> = ({ options, onChange, property, value }): JSX.Element => {

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="yes"
        name="radio-buttons-group"
        value={value}
        onChange={(e, value) => onChange(property, value)}
      >
        { options.map((option, index) => (
          <FormControlLabel key={index} value={option.value} control={<Radio color="secondary" />} label={<Typography sx={{ fontStyle: "italic"}} variant="textRegularNormal">{option.label}</Typography>} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioGroupInput;
