import { Box, styled } from "@mui/material";

const DiamondMinterStyle = styled(Box)(({ theme }) => ({
  h1: {
    padding: `${theme.spacing(4)} 0px`,
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.color17.main}`,
  },
  ".stepper": {
    justifyContent: "center",
    alignItems: "center",
  },
  ".MuiStepLabel-iconContainer.Mui-completed": {
    svg: {
      color: theme.palette.color9.main,
    },
  },
}));

export default DiamondMinterStyle;
