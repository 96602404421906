import { Color, PaletteOptions, ThemeOptions, createTheme } from "@mui/material";
import React from "react";

/** Color settings */
interface CustomColor extends Color {
  dark?: string;
  main: string;
  light?: string;
}

interface CustomPalette {
  color1: CustomColor;
  color2: CustomColor;
  color3: CustomColor;
  color4: CustomColor;
  color5: CustomColor;
  color6: CustomColor;
  color7: CustomColor;
  color8: CustomColor;
  color9: CustomColor;
  color10: CustomColor;
  color11: CustomColor;
  color12: CustomColor;
  color13: CustomColor;
  color14: CustomColor;
  color15: CustomColor;
  color16: CustomColor;
  color17: CustomColor;
  color18: CustomColor;
  color19: CustomColor;
  color20: CustomColor;
  color21: CustomColor;
  color22: CustomColor;
  color23: CustomColor;
}

declare module "@mui/material/styles" {
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

/** Typography settings */
declare module "@mui/material/styles" {
  interface TypographyVariants {
    textMediumNormal: React.CSSProperties;
    textSmallNormal: React.CSSProperties;
    textRegularNormal: React.CSSProperties;
    textSmallLink: React.CSSProperties;
    textUppercase: React.CSSProperties;
    textRegularSemibold: React.CSSProperties;
    texNormalSemibold: React.CSSProperties;
    textMediumSmall: React.CSSProperties;
    textSmallMedium: React.CSSProperties;
    textMediumBold: React.CSSProperties;
    textMediumSemibold: React.CSSProperties;
    texNormalbold: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    textMediumNormal?: React.CSSProperties;
    textSmallNormal?: React.CSSProperties;
    textRegularNormal: React.CSSProperties;
    textSmallLink: React.CSSProperties;
    textUppercase: React.CSSProperties;
    textRegularSemibold: React.CSSProperties;
    texNormalSemibold: React.CSSProperties;
    textMediumSmall: React.CSSProperties;
    textSmallMedium: React.CSSProperties;
    textSmallBold: React.CSSProperties;
    textMediumBold: React.CSSProperties;
    textMediumSemibold: React.CSSProperties;
    texNormalbold: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    textMediumNormal: true;
    textSmallNormal: true;
    textRegularNormal: true;
    textSmallLink: true;
    textUppercase: true;
    textRegularSemibold: true;
    textMediumSmall: true;
    texNormalSemibold: true;
    textSmallMedium: true;
    textSmallBold: true;
    textMediumBold: true;
    textMediumSemibold: true;
    texNormalbold: true;
  }
}
// Extend the Theme interface to include xxs breakpoint
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xxs: true;
    xxxs: true;
  }
}

/** Theme settings */
export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#2600FC",
      light: "#00A3FF",
    } as CustomColor,
    secondary: {
      dark: "#000000",
      main: "#505050",
      light: "#292929",
    } as CustomColor,
    color1: {
      dark: "rgba(255, 255, 255, 0.40)",
      main: "#ffffff",
    } as CustomColor,
    color2: {
      main: "#FF0000",
    } as CustomColor,
    color3: {
      main: "#D9D9D9",
    } as CustomColor,
    color4: {
      main: "#4043FF",
    } as CustomColor,
    color5: {
      main: "#F2C261",
      light: "#FFE500",
    } as CustomColor,
    color6: {
      main: "#6EFB74",
    } as CustomColor,
    color7: {
      main: "#F0F5FF",
    } as CustomColor,
    color8: {
      main: "#FFE500",
    } as CustomColor,
    color9: {
      main: "#039200",
      light: "#04CE00",
    } as CustomColor,
    color10: {
      main: "#B0D5FF",
    } as CustomColor,
    color11: {
      main: "#FF8181",
    } as CustomColor,
    color12: {
      main: "#FEF9E2",
      dark: "#F9EAA6",
    } as CustomColor,
    color13: {
      main: "#F4F4F4",
    } as CustomColor,
    color14: {
      main: "#C3C3C3",
    } as CustomColor,
    color15: {
      main: "#5F6C7D",
    } as CustomColor,
    color16: {
      main: "#9F9EA4",
    } as CustomColor,
    color17: {
      main: "#C7C7C7",
    } as CustomColor,
    color18: {
      main: "#D2D2D2",
    } as CustomColor,
    color19: {
      main: "#D8BC61",
    } as CustomColor,
    color20: {
      main: "#0075FF",
    } as CustomColor,
    color21: {
      main: "#EEE4B6",
    } as CustomColor,
    color22: {
      main: "#AC8D00",
    } as CustomColor,
    color23: {
      main: "#F0F0F0",
    } as CustomColor,
  } as PaletteOptions,

  breakpoints: {
    values: {
      xl: 1600,
      lg: 1440,
      md: 1024,
      sm: 992,
      xs: 600,
      xxs: 480,
      xxxs: 0,
    },
  },
  typography: {
    h1: {
      fontFamily: "SF UI Display Bold",
      fontSize: "48px",
      lineHeight: "57.6px",
      "@media (max-width:600px)": {
        fontSize: "32px",
        lineHeight: "38.4px",
      },
    },
    h2: {
      fontFamily: "SF UI Display Bold",
      fontSize: "40px",
      lineHeight: "48px",
    },
    h3: {
      fontFamily: "SF UI Display Bold",
      fontSize: "32px",
      lineHeight: "40px",
      textTransform: "uppercase",
    },
    h6: {
      fontFamily: "SF UI Display Bold",
      fontSize: "20px",
      lineHeight: "28px",
    },
    textMediumNormal: {
      fontFamily: "SF UI Display Light",
      fontSize: "18px",
      lineHeight: "27px",
    },
    textMediumBold: {
      fontFamily: "SF UI Display Bold",
      fontSize: "18px",
      lineHeight: "19px",
    },
    textMediumSemibold: {
      fontFamily: "SF UI Display Semibold",
      fontSize: "18px",
      lineHeight: "19px",
    },
    textSmallNormal: {
      fontFamily: "SF UI Display Light",
      fontSize: "14px",
      lineHeight: "21px",
    },
    textSmallMedium: {
      fontFamily: "SF UI Display Medium",
      fontSize: "14px",
      lineHeight: "21px",
    },
    textSmallLink: {
      fontFamily: "SF UI Display Light",
      fontSize: "14px",
      lineHeight: "21px",
    },
    textSmallBold: {
      fontFamily: "SF UI Display Bold",
      fontSize: "14px",
      lineHeight: "21px",
    },
    textUppercase: {
      fontFamily: "SF UI Display Bold",
      fontSize: "20px",
      lineHeight: "30px",
    },
    textRegularNormal: {
      fontFamily: "SF UI Display Light",
      fontSize: "16px",
      lineHeight: "24px",
    },
    textMediumSmall: {
      fontFamily: "SF UI Display Medium",
      fontSize: "16px",
      lineHeight: "24px",
    },
    texNormalSemibold: {
      fontFamily: "SF UI Display Semibold",
      fontSize: "16px",
      lineHeight: "20px",
    },
    texNormalbold: {
      fontFamily: "SF UI Display Bold",
      fontSize: "16px",
      lineHeight: "18px",
    },
    textRegularSemibold: {
      fontFamily: "SF UI Display Semibold",
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;
