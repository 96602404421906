import { ethers } from "ethers";
import { useStoreContext } from "../hooks/Contexts";
import { useState } from "react";
import { toast } from "react-toast";
import { collectTrumpCardsSite, minterAddress, tokenAddress } from "../utils/constants";
import { MINTER_ABI, TOKEN_ABI } from "../utils/abi";
import useCounterService from "./Counter";
import { OFFERS } from "../components/stepper/offers/OffersData";

const useContractService = () => {
  const { store, setStore, setTransactionInProgress } = useStoreContext();
  const [loading, setLoading] = useState(false);
  const { updateOffersWithRemaining } = useCounterService();

  const create = (addr: string, abi: any, providerOrSigner: any) => {
    return new ethers.Contract(addr, abi, providerOrSigner);
  };

  const setpolygonScanHash = (hash: string | null) => {
    setStore((prevState) => ({
      ...prevState,
      cart: {
        ...prevState.cart,
        polygonScanHash: hash,
      },
    }));
  };

  const purchasedWithCreditCardByWalletAddress = async (): Promise<boolean | undefined> => {
    const minterContract = create(minterAddress!, MINTER_ABI, store.web3Auth.web3AuthProvider);
    try {
      return await minterContract.purchasedWithCreditCard(store.cart.user.wallet);
    } catch (error) {
      console.error(error);
    }
    return undefined;
  };

  const getDiamondMintRecords = async (
    web3AuthProvider?: ethers.BrowserProvider,
    wallet?: string
  ) => {
    const minterContract = create(
      minterAddress!,
      MINTER_ABI,
      web3AuthProvider ?? store.web3Auth.web3AuthProvider
    );
    try {
      const mintRecords = await minterContract.mintRecords(wallet ?? store.cart.user.wallet);
      const obj = Object.entries(
        mintRecords.toObject() as unknown as {
          start: ethers.BigNumberish;
          minted: ethers.BigNumberish;
        }
      ).map(([key, value]) => {
        return { [key]: Number(value) };
      });
      return { start: obj[0].start, minted: obj[1].minted };
    } catch (error) {
      console.error(error);
    }
    return undefined;
  };

  const mintFromLocked = async (amount: number): Promise<any | undefined> => {
    try {
      const signer = await store.web3Auth.web3AuthProvider.getSigner();
      const minterContract = create(minterAddress as string, MINTER_ABI, signer);
      const mintTransaction = await minterContract.mintFromLocked(amount);
      return await mintTransaction;
    } catch (error) {
      console.error(error);
      toast.error((error as any).message);
    }
    return undefined;
  };

  const getTokenBalanceOfByWalletAddress = async (
    walletAddress: string,
    provider: ethers.BrowserProvider
  ): Promise<number | undefined> => {
    const tokenContract = create(tokenAddress!, TOKEN_ABI, provider);
    try {
      const balance = await tokenContract.balanceOf(walletAddress);
      return Number(balance);
    } catch (error) {
      console.error(error);
    }
    return undefined;
  };

  const approveWeth = async (
    wethContract: any,
    minterContractAddress: any,
    totalPriceToApprove: any
  ) => {
    try {
      const allowance = await wethContract.allowance(store.cart.user.wallet, minterContractAddress);
      if (parseInt(allowance.toString()) < parseInt(totalPriceToApprove.toString())) {
        const transaction = await wethContract.approve(minterContractAddress, totalPriceToApprove);
        setpolygonScanHash(transaction?.hash);
        await transaction.wait();
      }
      const newAllowance = await wethContract.allowance(
        store.cart.user.wallet,
        minterContractAddress
      );

      if (parseInt(newAllowance.toString()) < parseInt(totalPriceToApprove.toString())) {
        toast.error("Approve more wETH!");
        await approveWeth(wethContract, minterContractAddress, totalPriceToApprove);
      } else {
        return true;
      }
    } catch (error) {
      //@ts-ignore
      toast.error(error?.message);
      throw new Error("error while approving");
    }
  };

  const mint = async (
    minterContract: any,
    minterContractAddress: any,
    wethContract: any,
    userNonce: any,
    signature: any,
    saleDomain: any,
    contractPrice: any,
    contractPriceInEther: number,
    totalPrice: any,
    isDiamond = false
  ) => {
    try {
      setLoading(true);
      const updatedPackages = await updateOffersWithRemaining(OFFERS);
      const existsPackage = updatedPackages.find(
        (p) => p.packageType === store.cart.offer!.packageType
      );
      const remaining = existsPackage?.remaining;
      if (remaining && remaining <= 0) {
        toast.error(
          "Unfortunately the selected package has sold out. Please select a different package!"
        );
        setLoading(false);
        return;
      }
      await approveWeth(wethContract, minterContractAddress, totalPrice);

      const wethBalance = await wethContract.balanceOf(store.cart.user.wallet);
      if (wethBalance < contractPriceInEther) {
        toast.error("You don`t have enough wETH to mint!!!");
        setLoading(false);
        return;
      }
      console.log(
        "ezt kuldom",
        {
          saleDomain: saleDomain,
          buyer: store.cart.user.wallet,
          price: contractPrice,
          numberOfTokens: store.cart.offer?.numberOfTokens,
          packageType: store.cart.offer?.displayType,
          userNonce: userNonce,
        },
        signature
      );
      const estimateGas = await minterContract.buyPackage.estimateGas(
        {
          saleDomain: saleDomain,
          buyer: store.cart.user.wallet,
          price: contractPrice,
          numberOfTokens: store.cart.offer?.numberOfTokens,
          packageType: store.cart.offer?.displayType,
          userNonce: userNonce,
        },
        signature
      );

      const finalGasLimit = (BigInt(estimateGas) * BigInt(105)) / BigInt(100);

      const mintTransaction = await minterContract.buyPackage(
        {
          saleDomain: saleDomain,
          buyer: store.cart.user.wallet,
          price: contractPrice,
          numberOfTokens: store.cart.offer?.numberOfTokens,
          packageType: store.cart.offer?.displayType,
          userNonce: userNonce,
        },
        signature,
        {
          gasLimit: finalGasLimit,
        }
      );
      setpolygonScanHash(mintTransaction?.hash);
      await mintTransaction.wait();

      if (!isDiamond) {
        window.location.href = `${collectTrumpCardsSite}/thank-you?nftAmount=${store.cart.offer?.numberOfTokens}`;
      } else {
        setStore((prevState) => ({
          ...prevState,
          flowControl: {
            ...prevState.flowControl,
            activeStep: {
              ...prevState.flowControl.activeStep,
              title: "Checkout",
              component: store.flowControl.steps.diamondMinter.component,
            },
          },
        }));
        setpolygonScanHash(null);
        setLoading(false);
        setTransactionInProgress(false);
      }
      // TODO: navigate to the congrats page
    } catch (error) {
      setLoading(false);
      //@ts-ignore
      toast.error(error.message);
      console.error(error, " error");
    }
  };

  const getUsersMints = async (minterContract: any) => {
    const mintNumber = await minterContract.mintsByAddress(store.cart.user.wallet);
    return mintNumber;
  };
  return {
    create,
    mint,
    getUsersMints,
    loading,
    purchasedWithCreditCardByWalletAddress,
    getTokenBalanceOfByWalletAddress,
    getDiamondMintRecords,
    mintFromLocked,
  };
};

export default useContractService;
