import { Box, styled } from '@mui/material';

const TransactionLoaderStyle = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  padding: `20px 0`,
  ".MuiButton-root": {
    textTransform: "none !important"
  }
}));

export default TransactionLoaderStyle;
