import { FC, useEffect } from "react";
import { useStoreContext } from "../../hooks/Contexts";
import Header from "./header/Component";
import Footer from "./footer/Component";
import StepperStyle from "./Style";
import { Dialog, DialogContent } from "@mui/material";
import TransactionLoader from "../transaction-loader/Component";
import ProductSelector from "../product-selector/Components";

const Stepper: FC = (): JSX.Element => {
  const { store } = useStoreContext();

  useEffect(() => {
    if (!window) return;
    (function scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    })();
  }, [store.flowControl.activeStep]);

  return (
    <>
      <Header />
      <StepperStyle sx={{ maxWidth: `${store.containerMaxWidth}px !important` }}>
        {store.flowControl.activeStep.component}
      </StepperStyle>
      {store.cart.offer && store.flowControl.activeStep.componentName === "Offers" && (
        <ProductSelector />
      )}
      <Footer />
      <Dialog open={store.cart.isTransactionInProgress} fullScreen sx={{ zIndex: 999999 }}>
        <DialogContent>
          <TransactionLoader type="Transaction" />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Stepper;
