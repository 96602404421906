import { TextField } from "@mui/material";
import { FC, useEffect, useState } from "react";
import CountryInputStyle from "./Style";
import useCountryService from "../../../services/Country";
import DropdownModel from "../../../models/dropdown";
import { useStoreContext } from "../../../hooks/Contexts";

type Props = {
  label: string;
  property: string;
  value: string;
  onChange: (property: string, value: string) => void;
};

const INITIAL_SELECTED: DropdownModel = { id: "", label: "" };

const CountryInput: FC<Props> = ({ label, onChange, property, value }): JSX.Element => {
  const [options, setOptions] = useState<DropdownModel[]>([]);
  const [selected, setSelected] = useState<DropdownModel>(INITIAL_SELECTED);
  const { all } = useCountryService();
  const { store } = useStoreContext();

  useEffect(() => {
    let result: DropdownModel[] = all();
    if (store.cart.paymentMethod === "CreditCard") {
      result = result.filter((country) => country.label === "United States");
    }
    setOptions(result);
  }, [all, store.cart.paymentMethod]);

  useEffect(() => {
    if (!value || options.length === 0) return;
    const existsOption = options.find((option) => option.label === value);
    if (!existsOption) return;
    setSelected(existsOption);
  }, [options, value]);

  const clearHandler = () => {
    setSelected(INITIAL_SELECTED);
    onChange(property, "");
  };

  return (
    <CountryInputStyle
      disablePortal
      clearText=""
      value={selected.id ? selected : null}
      getOptionLabel={(option) => {
        const current = option as DropdownModel;
        return current.label;
      }}
      renderOption={(props, option) => {
        const model = option as DropdownModel;
        return (
          <li {...props} key={model.id}>
            {model.label}
          </li>
        );
      }}
      options={options}
      onChange={(e, value, react) => {
        if (react === "clear") return clearHandler();
        const model = value as DropdownModel;
        if (!model) return onChange(property, "");
        return onChange(property, model.label);
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

export default CountryInput;
