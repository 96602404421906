import { FC } from "react";
import TextInputStyle from "./Style";
import { TextFieldProps } from "@mui/material";

type Props = {
  label: string;
  property: string;
  value: string;
  onChange: (property: string, value: string) => void;
  props?: TextFieldProps;
}

const TextInput: FC<Props> = ({ value, onChange, property, label, props }): JSX.Element => {

  return (
    <TextInputStyle
      {...props}
      label={label}
      value={value ?? ""}
      onChange={(e) => onChange(property, e.target.value)}
      autoComplete="off"
      fullWidth
    />
  );
};

export default TextInput;
